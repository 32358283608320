import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Button, SideBar, Tag } from "antd-mobile";
import { switchLanguage } from "../../store/modules/lang/langSlice";
import { TableSVG } from "../../definition/customIcons";
import { Root } from "postcss";
import {
  getAllDishCategories,
  setActiveCategory,
  setShouldUpdateCategoryBadge,
  updateCategory,
} from "../../store/modules/category/categorySlice";
import { getAllDishData } from "../../store/modules/dish/dishSlice";
import DishesList from "./dishesList";

interface IProps {
  tableNumber: number;
}

const Category = memo(function (props: IProps) {
  const { tableNumber } = props;

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 菜品分类
  const { category, activeCategory, shouldUpdateCategoryBadge } = useSelector(
    (state: RootState) => state.category
  );

  // 购物车数据
  const { cartData } = useSelector((state: RootState) => state.cart);

  // 菜品
  const { dishes } = useSelector((state: RootState) => state.dishes);

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 是否通过打包带走的专用二维码进来的
  const { packing } = useSelector((state: RootState) => state.window);

  /**
   * 初始化 菜品分类 数据
   */
  useEffect(() => {
    if (category.length === 0) {
      dispatch(getAllDishCategories());
    }
  }, [category, dispatch]);

  /**
   * 更新 category 的 badge 值。
   */
  useEffect(() => {
    if (
      category.length > 0 &&
      // cartData.length > 0 &&
      shouldUpdateCategoryBadge
    ) {
      const tempCategoryWithBadge = category.map((cate) => {
        let badgeCount = 0;
        cartData.forEach((dishInCart) => {
          // cate.key 就是 categoryId，因为在 categorySlice中初始化 category时，将id替换成了key.
          if (dishInCart.categoryId === cate.key) {
            badgeCount += 1;
          }
        });
        if (badgeCount > 0) {
          return { ...cate, badge: badgeCount };
        } else {
          return {
            key: cate.key,
            title: cate.title,
          };
        }
      });
      dispatch(setShouldUpdateCategoryBadge(false));
      dispatch(updateCategory(tempCategoryWithBadge));
    }
  }, [category, cartData, shouldUpdateCategoryBadge, dispatch]);

  /**
   * 只要购物车生变化，重新计算 badge
   */
  useEffect(() => {
    if (!shouldUpdateCategoryBadge) {
      dispatch(setShouldUpdateCategoryBadge(true));
    }
  }, [cartData, dispatch]);

  /**
   * 初始化时默认选中 第一个菜品分类
   */
  useEffect(() => {
    if (category.length > 0 && activeCategory === "") {
      dispatch(setActiveCategory(category[0].key));
    }
  }, [category, activeCategory, dispatch]);

  /**
   * 初始化 菜品 数据
   */
  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(getAllDishData());
    }
  }, [dishes, dispatch]);

  return (
    <div className="w-full h-screen flex flex-col gap-2 bg-[#F7F7F7] overflow-auto scrollbar-hidden">
      <div
        className={
          "w-full h-12 flex flex-row justify-between items-center gap-2 px-4 bg-gradient-to-b from-[#FFD000] from-20% to-[#FAE47a]"
        }
      >
        <div className="text-lg">
          {setting.restaurantSetting.restaurantName}
        </div>
        <div className="flex flex-row justify-between items-start">
          <div className="flex items-center justify-end text-base">
            {packing ? (
              <Tag color="warning">Pack</Tag>
            ) : (
              <div className="flex flex-row items-center">
                <div className="size-5">
                  <TableSVG fontSize={12} />
                </div>
                <span className="pl-1">{tableNumber}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="h-full w-full flex flex-row justify-between">
        <SideBar
          activeKey={activeCategory}
          onChange={(key) => {
            dispatch(setActiveCategory(key));
          }}
        >
          {category.map((item) => (
            <SideBar.Item
              key={item.key}
              title={item.title}
              badge={item.badge}
            />
          ))}
        </SideBar>
        <div className="grow bg-white">
          <DishesList categoryId={activeCategory} />
        </div>
      </div>
    </div>
  );
});

export default Category;
