import React, { useEffect, useState } from "react";
import { ICartSpecification, IDish } from "../definition/customTypes";
import { CenterPopup, Mask, Modal, Selector } from "antd-mobile";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { setShowSpecificationModal } from "../store/modules/dish/specificationSlice";
import SpecificationRadio from "./specificationRadio";
import { CloseCircleOutline } from "antd-mobile-icons";
import CartButton from "./cartButton";
import { calculateFinalPrice } from "../store/modules/cart/cartSlice";
import { useTranslation } from "react-i18next";

/**
 * 菜品规格的选择弹框
 * @returns
 */
function DishSpecificationModal() {
  // 国际化
  const { t } = useTranslation();
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 购物车数据
  const { cartData, finalPrice, savedPrice } = useSelector(
    (state: RootState) => state.cart
  );

  // 控制 规格弹层 内容的 redux
  const { currentDish, showSpecificationModal } = useSelector(
    (state: RootState) => state.specification
  );

  // 临时保存当前菜品 已选择后的规格
  const [chosenSpecifications, setChosenSpecifications] = useState<
    ICartSpecification[]
  >([]);

  /**
   * 购物车数据变化时，重新计算价格
   */
  useEffect(() => {
    dispatch(calculateFinalPrice());
  }, [cartData]);

  return (
    <Mask visible={showSpecificationModal}>
      <div className="flex flex-col justify-between rounded-xl bg-white w-11/12 mx-auto mt-20">
        {/* header */}
        <div className="flex flex-row h-12 pl-4 items-center text-lg font-semibold rounded-t-xl bg-gray-100">
          {currentDish?.dishName}
        </div>
        {/* boddy */}
        <div className="px-4 max-h-96 overflow-y-auto pb-10">
          {currentDish?.dishSpecifications.map((specification, index) => {
            return (
              <div key={index}>
                <div
                  className={`font-medium mb-2 text-base ${
                    index === 0 ? "mt-4" : "mt-6"
                  }`}
                >
                  {specification.specificationName}
                  <span className="pl-3 text-xs text-gray-400">
                    {specification.required ? t("app.required") : ""}
                  </span>
                </div>
                <SpecificationRadio
                  specification={specification}
                  chosenSpecifications={chosenSpecifications}
                  setChosenSpecifications={setChosenSpecifications}
                />
              </div>
            );
          })}
        </div>

        {/* footer */}
        <div className="flex flex-col justify-between px-4 mt-10 h-24 rounded-b-xl bg-gray-100">
          <div className="flex flex-row mt-2 gap-2">
            <div className="shrink-0">{t("app.cart.chosenSpecification")}:</div>
            <div>
              {chosenSpecifications.map((s, index) => {
                return index === 0
                  ? s.chosenItem?.itemName
                  : ", " + s.chosenItem?.itemName;
              })}
            </div>
          </div>
          <div className="flex flex-row justify-between mb-2">
            <div className="flex flex-row gap-2 text-base font-semibold items-center">
              <div>{t("app.total")}</div>
              <div className="text-2xl text-[#FE4A34]">
                <span className="text-xs">$</span>
                {finalPrice}
              </div>
            </div>
            <div>
              <CartButton
                currentDish={currentDish as IDish}
                isDetail={true}
                idPrefix="specification"
                chosenSpecifications={chosenSpecifications}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-11/12 mx-auto flex justify-center mt-4">
        <CloseCircleOutline
          fontSize={34}
          color="white"
          onClick={() => dispatch(setShowSpecificationModal(false))}
        />
      </div>
    </Mask>
  );
}

export default DishSpecificationModal;
