import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../index";
import {
  CategoryType,
  ApiResponse,
  CategoryFromBankendType,
} from "../../../definition/customTypes";
import axiosInstance from "../../../definition/request";

/**
 * Slice 中的数据结构规范
 */
interface ICategorySlice {
  category: CategoryType[];
  activeCategory: string; // 当前选中的 菜品分类
  shouldUpdateCategoryBadge: boolean; // 是否需要更新 category 中的 badge, 当加入和移除购物车时，需要调用此方法 setShouldUpdateCategoryBadge(true)
}

/**
 * Slice 中数据的初始状态
 */
const initialState: ICategorySlice = {
  /** 视图数据集 */
  category: [],
  activeCategory: "",
  shouldUpdateCategoryBadge: true,
};

const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    setCategory(state, action) {
      const tempCategory = action.payload.map(
        (item: CategoryFromBankendType) => {
          return {
            key: item.id,
            title: item.name,
          };
        }
      );
      state.category = tempCategory;
    },
    setActiveCategory(state, action) {
      state.activeCategory = action.payload;
    },
    updateCategory(state, action) {
      state.category = action.payload;
    },
    setShouldUpdateCategoryBadge(state, action) {
      state.shouldUpdateCategoryBadge = action.payload;
    },
  },
});

const {
  setCategory,
  setActiveCategory,
  updateCategory,
  setShouldUpdateCategoryBadge,
} = categorySlice.actions;
const reducer = categorySlice.reducer;

/**
 * 从后端加载所有的 菜品分类 数据.
 * @returns
 */
const getAllDishCategories = () => {
  return (dispatch: AppDispatch) => {
    const url: string = "/menu-factory/getAllDishCategories";
    axiosInstance
      .get<ApiResponse>(url)
      .then((response) => {
        const apiResponse: ApiResponse = response.data;
        // 成功，
        if (apiResponse.code === 200) {
          if (apiResponse.data.length === 0) return; // 如果菜品数据为空，则直接返回，不需要设置。防止页面无限请求。
          // console.log(apiResponse.data);
          dispatch(setCategory(apiResponse.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Response error:", error.response.data);
          console.log("Error status:", error.response.status);
        } else {
          console.error("Other error:", error);
        }
      });
  };
};

export {
  getAllDishCategories,
  setActiveCategory,
  updateCategory,
  setShouldUpdateCategoryBadge,
};
export default reducer;
