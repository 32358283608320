import { Selector, SelectorOption } from "antd-mobile";
import React, { useEffect, useState } from "react";
import {
  ICartSpecification,
  ISpecification,
  ISpecificationItem,
} from "../definition/customTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface IProps {
  specification: ISpecification; // radio 的选项，父级传递过来的某个规格的子项
  chosenSpecifications: ICartSpecification[]; // 父级传递过来的已经选择好的规格
  setChosenSpecifications: (chosenSpecifications: ICartSpecification[]) => void;
}

/**
 * 选择器组件，用于加购时的规格选择
 * @param props
 * @returns
 */
function SpecificationRadio(props: IProps) {
  const { specification, chosenSpecifications, setChosenSpecifications } =
    props;

  // 购物车数据
  const { cartData } = useSelector((state: RootState) => state.cart);

  // 控制 规格弹层 内容的 redux
  const { currentDish } = useSelector(
    (state: RootState) => state.specification
  );

  // 选择的项目-名称
  const [chosenItemName, setChosenItemName] = useState("");

  // 选择器中的可选项
  const [options, setOptions] = useState<SelectorOption<string>[]>([]);

  // 存放 item 名称和 item 对象的映射
  // key: itemName, value: item
  const [itemsRecord, setItemsRecord] = useState<Record<
    string,
    ISpecificationItem
  > | null>(null);

  /**
   * 为已经存在购物车的菜品，选择好最近一次选择的规格项
   */
  useEffect(() => {
    if (currentDish) {
      // 1. 找出相同菜品
      const sameDishesIncart = cartData.filter(
        (dish) => dish.dishId === currentDish.dishId
      );
      if (sameDishesIncart.length > 0) {
        const latestAddedIntoCart =
          sameDishesIncart[sameDishesIncart.length - 1]; // 取出最后一个菜品
        const previoisChosenSpecification = // 找出该菜品最近一次选择对应的规格项
          latestAddedIntoCart.chosenSpecifications.filter(
            (s) => s.specificationName === specification.specificationName
          );
        setChosenItemName(previoisChosenSpecification[0]?.chosenItem.itemName);
      } else {
        setChosenItemName("");
        setChosenSpecifications([]);
      }
    }
  }, [currentDish, cartData]);

  /**
   * 初始化选择项
   */
  useEffect(() => {
    setChosenSpecifications([]); // 清空上一个菜品的规格选择数据
    const items = specification.specificationItems;
    const initItemsRecord: Record<string, ISpecificationItem> = {};
    if (items.length > 0) {
      const options = items.map((item) => {
        initItemsRecord[item.itemName] = item;
        return {
          label: (
            <div>
              {item.itemName}{" "}
              {item.itemPrice > 0 ? (
                <span className="text-gray-300">|</span>
              ) : (
                ""
              )}
              <span className="text-[#FE4A34]">
                {item.itemPrice === 0 ? (
                  ""
                ) : (
                  <>
                    <span className="text-xs"> +$</span>
                    {""}
                    <span>{item.itemPrice}</span>
                  </>
                )}
              </span>
            </div>
          ),
          value: item.itemName,
        };
      });
      setItemsRecord(initItemsRecord);
      setOptions(options);
    }
  }, [specification]);

  /**
   * 将选择好的规格项目，存放并更新到 chosenSpecifications
   */
  useEffect(() => {
    if (chosenItemName !== "" && itemsRecord !== null) {
      let tempChosenSpeci = [...chosenSpecifications];
      //   console.log("chosenItem = ", chosenItemName);
      const existedSpecification = chosenSpecifications.filter(
        (s) => s.specificationName === specification.specificationName
      );
      if (existedSpecification.length === 0) {
        tempChosenSpeci.push({
          specificationName: specification.specificationName,
          chosenItem: itemsRecord[chosenItemName],
        });
      } else {
        tempChosenSpeci = chosenSpecifications.map((s) => {
          if (s.specificationName === specification.specificationName) {
            return { ...s, chosenItem: itemsRecord[chosenItemName] };
          }
          return s;
        });
      }
      //   console.log("tempChosenSpeci = ", tempChosenSpeci);
      setChosenSpecifications(tempChosenSpeci);
    }
  }, [chosenItemName]);

  return (
    <Selector
      options={options}
      value={[chosenItemName]}
      style={{
        "--border-radius": "8px",
        "--padding": "4px 12px",
        "--checked-text-color": "var(--adm-color-warning)",
        "--checked-color": "#FFF9F0",
        "--border": "solid 1px transparent",
        "--checked-border": "solid 1px var(--adm-color-warning)",
      }}
      onChange={(v) => {
        if (v.length) {
          setChosenItemName(v[0]);
        }
      }}
    />
  );
}

export default SpecificationRadio;
