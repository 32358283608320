import { AppDispatch } from "../store";
import { AxiosResponse } from "axios";
import axiosInstance from "./request";
import { ApiResponse } from "./customTypes";

/**
 * 定义一个非阻塞的延迟处理函数
 * @param ms 等待 ms 秒后执行该函数
 * @returns
 */
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * 定义一个方法，加载一张菜品图片
 *
 */
export const loadDishImage = async (imageId: string): Promise<string> => {
  let imageURL = "";
  const url: string = "/menu-factory/loadDishImage/" + imageId + "/image";
  const response = await axiosInstance.get(url, { responseType: "blob" });

  // 成功，
  if (response.status === 200) {
    imageURL = URL.createObjectURL(response.data);
  }
  return imageURL;
};

/**
 * 定义一个方法，加载一张菜品缩略图片
 *
 */
export const loadDishThumbnail = async (imageId: string): Promise<string> => {
  let imageURL = "";
  const url: string = "/menu-factory/loadDishThumbnail/" + imageId + "/image";
  const response = await axiosInstance.get(url, { responseType: "blob" });

  // 成功，
  if (response.status === 200) {
    imageURL = URL.createObjectURL(response.data);
  }
  return imageURL;
};
