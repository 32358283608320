import { createSlice } from "@reduxjs/toolkit";
import { act, RefObject, useRef } from "react";
import { IDish } from "../../definition/customTypes";

/**
 * 菜品坠入到购物车时的轨迹动画，控制参数
 */

interface IItemDrop {
  isAnimating: boolean;
  cartRefId: string; // 目标位置：购物车所在的 div id值.
  dishRefId: string; // 起始位置：菜品所在位置的 div id值。
  imgURL: string; // 菜品的图片封面URL
}

const initialState: IItemDrop = {
  isAnimating: false,
  cartRefId: "",
  dishRefId: "",
  imgURL: "",
};

const itemDropSlice = createSlice({
  name: "itemDrop",
  initialState: initialState,
  reducers: {
    setIsAnimating(state, action) {
      state.isAnimating = action.payload;
    },

    setCartRefId(state, action) {
      state.cartRefId = action.payload;
    },
    setDishRefId(state, action) {
      state.dishRefId = action.payload;
    },
    setImgURL(state, action) {
      state.imgURL = action.payload;
    },
  },
});

const {
  setIsAnimating,

  setCartRefId,
  setDishRefId,
  setImgURL,
} = itemDropSlice.actions;

const reducer = itemDropSlice.reducer;

export { setIsAnimating, setCartRefId, setDishRefId, setImgURL };
export default reducer;
