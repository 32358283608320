import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../definition/request";
import { ApiResponse, ISetting } from "../../../definition/customTypes";
import { AppDispatch } from "../..";

/**
 * Slice 中的数据结构规范
 */
interface ISettingSlice {
  setting: ISetting;
  isInitialized: boolean;
}

/**
 * Slice 中数据的初始状态
 */
const initialState = {
  setting: {
    basicSetting: {
      majorLanguage: "",
    },
    diningSetting: {
      payFirst: false,
      autoCancelOrderDelayMinutes: 0,
    },
    restaurantSetting: {
      restaurantName: "",
    },
  },
  isInitialized: false,
};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    /** 设置 设置数据 */
    setMerchantSetting(state, action) {
      state.setting = action.payload;
    },
    /** 设置数据 是否完成加载 */
    setIsInitialized(state, action) {
      state.isInitialized = action.payload;
    },
  },
});

const { setMerchantSetting, setIsInitialized } = settingSlice.actions;
const reducer = settingSlice.reducer;

/**
 * 初始化加载 商家的设置数据
 */
const loadMerchantSetting = () => {
  return async (dispatch: AppDispatch) => {
    const url = "/merchants-onboarding/getMerchantSettingsForMobile";
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;

      // 成功
      if (apiResponse.code === 200) {
        const settingResponse = apiResponse.data;
        dispatch(setIsInitialized(true));
        if (!settingResponse) {
          return;
        }
        const { majorLanguage } = settingResponse.basicSetting
          ? settingResponse.basicSetting
          : { majorLanguage: "" };
        const { payFirst, autoCancelOrderDelayMinutes } =
          settingResponse.diningSetting
            ? settingResponse.diningSetting
            : { payFirst: false, autoCancelOrderDelayMinutes: 0 };
        const { restaurantName } = settingResponse.restaurantSetting
          ? settingResponse.restaurantSetting
          : { restaurantName: "" };

        dispatch(
          setMerchantSetting({
            basicSetting: { majorLanguage },
            diningSetting: { payFirst, autoCancelOrderDelayMinutes },
            restaurantSetting: { restaurantName },
          })
        );
        dispatch(setIsInitialized(true));
      }
    });
  };
};

export { loadMerchantSetting, setIsInitialized };
export default reducer;
