import React, { memo, RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { Badge, Button, Image, Skeleton, Tabs } from "antd-mobile";
import {
  initRecommendlData,
  updateImageCove,
} from "../../../store/modules/recommend/recommendSlice";
import { LikeOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import {
  setDishIdInDetailPage,
  setPage,
} from "../../../store/modules/windowSlice";
import CartButton from "../../../component/cartButton";
import { IDish } from "../../../definition/customTypes";
import {
  setDishRefId,
  setIsAnimating,
} from "../../../store/modules/itemDropSlice";

interface FallingItem {
  id: string;
  img: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

const Recommend = memo(function () {
  // 国际化
  const { t } = useTranslation();

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 保存到 Redux 中的 merchantId, index.tsx 中初始化
  const { merchantId } = useSelector((state: RootState) => state.lang);

  const { recommend, isDataReady, isImageCoverLoaded } = useSelector(
    (state: RootState) => state.recommend
  );

  const { token } = useSelector((state: RootState) => state.window);

  /**
   * 加载 主厨推荐数据
   */
  useEffect(() => {
    if (recommend.length === 0 && merchantId !== "" && token !=="") {
      dispatch(initRecommendlData());
    }
  }, [recommend, merchantId, token, dispatch]);

  /**
   * 加载 主厨推荐图片
   */
  useEffect(() => {
    if (isDataReady && !isImageCoverLoaded) {
      dispatch(updateImageCove(recommend));
    }
  }, [isDataReady, isImageCoverLoaded, dispatch]);

  // 视图宽度
  const { vWidth } = useSelector((state: RootState) => state.window);

  // 主厨推荐的图片大小
  const [imageSize, setImageSize] = useState(0);

  const recommendPlaceholder = [1, 2, 3];

  /**
   * 计算 预设的图片 宽高
   */
  useEffect(() => {
    if (imageSize === 0) {
      const size = (vWidth - 16) / 2 - 20;
      setImageSize(size);
    }
  }, [imageSize, vWidth]);

  return (
    <div
      className="flex flex-col h-full rounded-lg bg-white mt-0"
      style={{ "--adm-color-border": "transparent" } as React.CSSProperties}
    >
      <Tabs
        activeLineMode="fixed"
        style={{
          "--title-font-size": "18px",
          "--fixed-active-line-width": "0px",
          "--content-padding": "0",
        }}
        stretch={false}
      >
        <Tabs.Tab
          title={
            <div className="flex flex-row items-start gap-1">
              <LikeOutline fontSize={22} />

              <div>{t("app.home.recommend")}</div>
            </div>
          }
          key="recommend"
        >
          <div className="flex flex-row justify-start gap-2 pb-2 mt-[-5px] mx-2 overflow-x-auto scrollbar-hidden">
            {recommend.length > 0
              ? recommend.map((dish, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex flex-col gap-2`}
                      style={{ width: imageSize }}
                    >
                      {dish.dishStatus === "SOLD_OUT" ? (
                        <Badge
                          content={t("app.detail.stock.soldOut")}
                          style={{ "--right": "18%", "--top": "8%" }}
                          className="py-1"
                        >
                          <Image
                            src={recommend[index]?.imageCover}
                            width={imageSize}
                            height={imageSize}
                            fit="cover"
                            className="rounded-md"
                            onClick={() => {
                              dispatch(setDishIdInDetailPage(dish.dishId));
                              dispatch(setPage("detail"));
                            }}
                          />
                        </Badge>
                      ) : (
                        <Image
                          src={recommend[index]?.imageCover}
                          width={imageSize}
                          height={imageSize}
                          fit="cover"
                          className="rounded-md"
                          onClick={() => {
                            dispatch(setDishIdInDetailPage(dish.dishId));
                            dispatch(setPage("detail"));
                          }}
                        />
                      )}

                      <div
                        className={`min-w-0 truncate whitespace-nowrap overflow-hidden text-ellipsis w-full text-base`}
                        onClick={() => {
                          dispatch(setDishIdInDetailPage(dish.dishId));
                          dispatch(setPage("detail"));
                        }}
                      >
                        {recommend[index]?.dishName}
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="text-[#FE4A34] text-lg flex flex-row gap-1">
                          <div>
                            <span className="text-xs">$</span>
                            {dish.discounting
                              ? dish.preferentialPrice
                              : dish.price}
                          </div>
                          {dish.discounting ? (
                            <div className="text-[#A4A1A1] text-sm flex items-center line-through">
                              <span className="text-xs">$</span>
                              {dish.price}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="mr-2">
                          <CartButton
                            currentDish={dish}
                            isDetail={false}
                            idPrefix="recommend"
                            chosenSpecifications={[]}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : recommendPlaceholder.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-2"
                      style={{ width: imageSize }}
                    >
                      {/* 骨架图片 */}
                      <Skeleton
                        animated
                        style={{
                          width: imageSize,
                          height: imageSize,
                          borderRadius: "8px",
                        }}
                      />
                      {/* 骨架标题 */}
                      <Skeleton.Title
                        animated
                        style={{
                          marginTop: "2px",
                          width: imageSize * 0.8,
                          height: "16px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  );
                })}
          </div>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
});

export default Recommend;
