import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Button, ErrorBlock, Footer, Image, Tag } from "antd-mobile";
import { FoodIcon, TableSVG } from "../../definition/customIcons";
import { setHomeActivePannel } from "../../store/modules/windowSlice";
import {
  loadAllUncompletedOrders,
  setCurrentOrders,
} from "../../store/modules/order/orderSlice";
import {
  getAllDishData,
  updateImageCove,
} from "../../store/modules/dish/dishSlice";
import OrderDetail from "./orderDetail";
import { Order } from "../../definition/customTypes";

function Orders() {
  // 国际化
  const { t } = useTranslation();

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 上一个页面 餐桌号
  const { tableNumber, tableId } = useSelector(
    (state: RootState) => state.window
  );

  // 菜品
  const { dishes } = useSelector((state: RootState) => state.dishes);

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 当前订单数据
  const { currentOrders } = useSelector((state: RootState) => state.order);

  // 控制订单详情的弹框
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);

  // 传递到 订单详情页的 order
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);

  // 是否通过打包带走的专用二维码进来的
  const { packing } = useSelector((state: RootState) => state.window);

  /**
   * 初始化 菜品 数据
   */
  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(getAllDishData());
    }
  }, [dishes, dispatch]);

  /**
   * 加载该餐桌 所有未完成的订单
   */
  useEffect(() => {
    if (currentOrders.length === 0) {
      dispatch(loadAllUncompletedOrders(tableId));
    }
  }, [currentOrders]);

  /**
   * 更新 订单中的菜品图片
   */
  useEffect(() => {
    if (currentOrders.length > 0 && dishes.length > 0) {
      /** 1. 首先在所有订单中找出 没有图片地址的所有菜品Id */
      let dishIdsNoCover: string[] = [];
      currentOrders.forEach((order) => {
        order.dishes.forEach((dish) => {
          if (dish.imageCover === "") {
            // 因为加入购物车时，菜品的图片为空串 “”
            dishIdsNoCover.push(dish.dishId);
          }
        });
      });
      if (dishIdsNoCover.length === 0) {
        return;
      }

      /** 2. 在原菜品集合中找出对应Id 且 没有图片的菜品 */
      const coDishes = dishes.filter((dish) => {
        return dishIdsNoCover.some(
          (dishId) => dishId === dish.dishId && !dish.imageCover
        );
      });
      // debugger;

      /** 3. 如果存在，则更新原菜品集合中菜品的图片 */
      if (coDishes.length > 0) {
        dispatch(updateImageCove(coDishes));
      } else {
        /** 4. 如果不存在，说明原菜品集合中的菜品都有图片，那么将这些图片更新到 订单的菜品中 */
        // 4.1 将菜品集合转化成一个Map. Key: dishId, Value: imageCover
        const dishIdAndImageMap = new Map(
          dishes.map((dish) => [dish.dishId, dish.imageCover])
        );

        const tempCurrentOrders = currentOrders.map((order) => {
          const tempDishes = order.dishes.map((dishInOrder) => {
            return {
              ...dishInOrder,
              imageCover: dishIdAndImageMap.get(dishInOrder.dishId),
            };
          });
          return { ...order, dishes: tempDishes };
        });
        dispatch(setCurrentOrders(tempCurrentOrders));
      }
    }
  }, [currentOrders, dishes]);

  return (
    <div className="w-screen h-screen flex flex-col justify-between gap-2 bg-[#F7F7F7] overflow-auto scrollbar-hidden">
      <div className="flex flex-col gap-2">
        {/* 头部 */}
        <div
          className={
            "h-12 flex flex-row justify-between items-center gap-2 pr-4 bg-gradient-to-b from-[#FFD000] from-20% to-[#FAE47a]"
          }
        >
          <div className="text-lg ml-4">
            {setting.restaurantSetting.restaurantName}
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="flex items-center justify-end text-base">
              {packing ? (
                <Tag color="warning">Pack</Tag>
              ) : (
                <div className="flex flex-row items-center">
                  <div className="size-5">
                    <TableSVG fontSize={12} />
                  </div>
                  <span className="pl-1">{tableNumber}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {currentOrders.length === 0 ? (
          <div className="flex flex-col items-center mt-20 gap-4">
            <div>
              <ErrorBlock
                image={<FoodIcon />}
                style={{
                  "--image-height": "100px",
                }}
                title={t("app.cart.empty.title")}
                description={<span>{t("app.cart.empty.description")}</span>}
              ></ErrorBlock>
            </div>
            <div>
              <Button
                className="text-gray-500"
                onClick={() => {
                  dispatch(setHomeActivePannel("home"));
                }}
              >
                {t("app.order.tipsOnButton")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 mb-20 overflow-auto">
            {currentOrders.map((order, index) => {
              return (
                <div
                  key={order.id}
                  className="flex flex-col gap-2 bg-white mx-2 p-2 rounded-lg drop-shadow-sm"
                  onClick={() => {
                    setCurrentOrder(order);
                    setOrderDetailVisible(true);
                  }}
                >
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row justify-start gap-2">
                      {order.packing ? (
                        <div>
                          <Tag color="warning" fill="outline">
                            Pack
                          </Tag>
                          <span className="font-semibold">
                            {" "}
                            {order.packingCode}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {order.paid ? (
                        <Tag color="success" fill="outline" className="p-1">
                          {t("app.order.paid")}
                        </Tag>
                      ) : (
                        <Tag color="danger" fill="outline" className="p-1">
                          {t("app.order.unPaid")}
                        </Tag>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="grow flex flex-row gap-2 overflow-auto pr-16">
                      {order.dishes.map((dish) => {
                        return (
                          <div
                            key={dish.dishId}
                            className="flex flex-col gap-2"
                          >
                            <div>
                              <Image
                                src={dish.imageCover}
                                className="w-24 h-16 rounded-lg"
                                fit="cover"
                              />
                            </div>
                            <div className="w-24 h-5 truncate overflow-hidden">
                              {dish.dishName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="font-semibold w-16 h-[92px] flex justify-center items-center absolute right-0 bg-white opacity-90">
                      ${order.finalPrice.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="w-full flex flex-row justify-center items-center">
              <Footer
                label={t("app.home.endPage")}
                className="w-full h-36 bg-transparent"
              ></Footer>
            </div>
          </div>
        )}
      </div>

      {/* 订单详情页 */}
      <OrderDetail
        currentOrder={currentOrder as Order}
        setCurrentOrder={setCurrentOrder}
        orderDetailVisible={orderDetailVisible}
        setOrderDetailVisible={setOrderDetailVisible}
      />
    </div>
  );
}

export default Orders;
