import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../index";
import axiosInstance from "../../../definition/request";
import {
  ApiResponse,
  ICartDish,
  ICartSpecification,
} from "../../../definition/customTypes";
import { IDish } from "../../../definition/customTypes";

/**
 * Slice 中的数据结构规范
 */
interface ICartSlice {
  cartData: ICartDish[]; // 购物车数据
  finalPrice: number; // 购物车菜品的优惠后总价
  savedPrice: number; // 购物车菜品的总优惠价格
}

/**
 * Slice 中数据的初始状态
 */
const initialState: ICartSlice = {
  cartData: [],
  finalPrice: 0,
  savedPrice: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    /** 计算购物车中的菜品总价 */
    calculateFinalPrice(state) {
      const tempCartData = [...state.cartData];
      let totalPrice = 0;
      let discountedPrice = 0;
      tempCartData.forEach((dish) => {
        // 折扣计算
        if (dish.discounting) {
          totalPrice += dish.preferentialPrice;
          discountedPrice += parseFloat(dish.price) - dish.preferentialPrice;
        } else {
          totalPrice += parseFloat(dish.price);
        }

        // 规格中的价格计算
        const csns = dish.chosenSpecifications;
        if (csns.length > 0) {
          csns.forEach((cs) => {
            if (cs.chosenItem.itemPrice > 0) {
              totalPrice += cs.chosenItem.itemPrice;
            }
          });
        }
      });
      state.finalPrice = parseFloat(totalPrice.toFixed(2));
      state.savedPrice = parseFloat(discountedPrice.toFixed(1));
    },
    setCartData(state, action) {
      const cartData = action.payload;
      // 如果缓存的购物车中没有数据，则返回，防止无限请求.
      if (cartData.length === 0) return;
      state.cartData = action.payload;
    },
    /** 清空购物车数据 */
    emptyCartData(state) {
      state.cartData = [];
    },
    /** Push 一个菜品进入购物车 */
    pushNewDishIntoCartData(state, action) {
      const { cartDish } = action.payload;
      const tempCartData = [...state.cartData, cartDish];
      state.cartData = tempCartData;
    },
    /** 从购物车中移除一个指定ID的菜品 */
    removeOneDishFromCartData(state, action) {
      const { dishId, index } = action.payload;
      const tempCartData = state.cartData.filter((dishInCart) => {
        if (dishInCart.dishId === dishId && dishInCart.index === index) {
          return false;
        }
        return true;
      });

      state.cartData = tempCartData;
    },
  },
});

const {
  calculateFinalPrice,
  setCartData,
  emptyCartData,
  pushNewDishIntoCartData,
  removeOneDishFromCartData,
} = cartSlice.actions;
const reducer = cartSlice.reducer;

/**
 * 加载购物车中所有的数据
 */
const loadAllDishCartData = (tableId: string) => {
  return async (dispatch: AppDispatch) => {
    const url = "/dish-order/loadAllDishCartData?tableId=" + tableId;
    const response = await axiosInstance.get<ApiResponse>(url);

    const apiResponse: ApiResponse = response.data;
    // 成功
    if (apiResponse.code === 200) {
      dispatch(setCartData(apiResponse.data));
    }
  };
};

/**
 * 将一个不包含规格的菜品，保存到后端的购物车
 * @returns
 */
const saveDishIntoCart = (
  tableId: string,
  dish: IDish,
  chosenSpecifications: ICartSpecification[]
) => {
  return async (dispatch: AppDispatch) => {
    const url = "/dish-order/saveDishIntoCart?tableId=" + tableId;

    // 构造请求的 Payload (放入到购物车缓存中)
    const cartDish = {
      dishId: dish.dishId,
      dishName: dish.dishName,
      categoryId: dish.categoryId,
      dishWeight: dish.dishWeight,
      price: dish.price,
      discounting: dish.discounting,
      preferentialPrice: dish.preferentialPrice,
      chosenSpecifications: chosenSpecifications,
      allowPacking: dish.allowPacking,
      imageCover: "", // 图片地址不保存到后端，因为Blob图片地址在会话结束后就会失效.
      coverImageId: dish.dishImages[0].uid,
    };

    const response = await axiosInstance.post<ApiResponse>(url, cartDish);

    const apiResponse: ApiResponse = response.data;
    // 成功
    if (apiResponse.code === 201) {
      dispatch(pushNewDishIntoCartData({ cartDish: apiResponse.data }));
    }
  };
};

/**
 * 将一个菜品从后端购物车缓存中移除
 * @param dish
 * @returns
 */
const deleteDishFromCart = (
  tableId: string,
  dish: ICartDish,
  index: number
) => {
  return async (dispatch: AppDispatch) => {
    const url =
      "/dish-order/deleteDishFromCart/" +
      dish.dishId +
      "/" +
      index +
      "?tableId=" +
      tableId;

    const response = await axiosInstance.delete<ApiResponse>(url);

    const apiResponse: ApiResponse = response.data;
    // 成功
    if (apiResponse.code === 200) {
      dispatch(removeOneDishFromCartData({ dishId: dish.dishId, index }));
    }
  };
};

export {
  setCartData,
  calculateFinalPrice,
  loadAllDishCartData,
  saveDishIntoCart,
  deleteDishFromCart,
  emptyCartData,
};
export default reducer;
