import { useEffect, useState } from "react";
import { FoodIcon, TableSVG } from "../../definition/customIcons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  Button,
  Dialog,
  Divider,
  ErrorBlock,
  Image,
  Input,
  NavBar,
  Popover,
  Result,
  Switch,
  Tag,
  Toast,
} from "antd-mobile";
import { setHomeActivePannel, setPage } from "../../store/modules/windowSlice";
import {
  getAllDishData,
  updateImageCove,
} from "../../store/modules/dish/dishSlice";
import {
  deleteDishFromCart,
  emptyCartData,
  setCartData,
} from "../../store/modules/cart/cartSlice";
import { CloseOutline, CheckOutline, DeleteOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import { ApiResponse, ICartDish } from "../../definition/customTypes";
import axiosInstance from "../../definition/request";
import { setCurrentOrders } from "../../store/modules/order/orderSlice";
import AutoCancelOrderCountdown from "../../component/autoCancelOrderCountdown";
import { useSwipeable } from "react-swipeable";

/**
 * 购物车页面
 * @returns
 */
function Cart() {
  // 国际化
  const { t } = useTranslation();

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 当前订单数据
  const { currentOrders } = useSelector((state: RootState) => state.order);

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 购物车数据
  const { cartData, finalPrice, savedPrice } = useSelector(
    (state: RootState) => state.cart
  );

  // 菜品
  const { dishes } = useSelector((state: RootState) => state.dishes);

  // 上一个页面 餐桌号
  const { previousPage, tableNumber, tableId } = useSelector(
    (state: RootState) => state.window
  );

  // 是否打包带走
  const [packChecked, setPackChecked] = useState(false);

  // 厨师留言
  const [note, setNote] = useState("");

  // 控制 立即下单按钮的 loading 状态
  const [loading, setLoading] = useState(false);

  // 是否已下单
  const [orderPlaced, setOrderPlaced] = useState(false);

  // 是否通过打包带走的专用二维码进来的
  const { packing } = useSelector((state: RootState) => state.window);

  /**
   * 监听页面右滑操作，若右滑则返回到 previousPage
   */
  const rightSwipHandler = useSwipeable({
    onSwipedRight: () => dispatch(setPage(previousPage)),
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  /**
   * 初始化 菜品 数据
   */
  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(getAllDishData());
    }
  }, [dishes, dispatch]);

  /**
   * 更新 购物车内的图片
   */
  useEffect(() => {
    if (cartData.length > 0 && dishes.length > 0) {
      /** 1. 首先找出 购物车中没有图片地址的菜品 */
      const cartDishesNoCover = cartData.filter(
        (cartDish) => cartDish.imageCover === "" // 因为加入购物车时，菜品的图片为空串 “”
      );
      if (cartDishesNoCover.length === 0) {
        return;
      }

      /** 2. 找出原菜品集合中对应的购物车菜品 且 该菜品没有图片 */
      const coDishes = dishes.filter((dish) => {
        return cartDishesNoCover.some(
          (d) => d.dishId === dish.dishId && !dish.imageCover
        );
      });
      // debugger;

      /** 3. 如果存在，则更新原菜品集合中菜品的图片 */
      if (coDishes.length > 0) {
        dispatch(updateImageCove(coDishes));
      } else {
        /** 4. 如果不存在，说明原菜品集合中的菜品都有图片，那么将这些图片更新到 购物车的菜品中 */
        // 4.1 先 替换 cartDishesNoCover 中的图片
        const temCartDishesWithCover = cartDishesNoCover.map((cartDish) => {
          const dishInDishes = dishes.filter(
            (dish) => dish.dishId === cartDish.dishId
          );
          return { ...cartDish, imageCover: dishInDishes[0].imageCover };
        });

        // 4.2 将 temCartDishesWithCover 转换成 Map
        const temCartDishesWithCoverMap = new Map(
          temCartDishesWithCover.map((cartDish) => [
            cartDish.dishId + cartDish.index,
            cartDish,
          ])
        );
        // 4.3 更新 cartData
        const tempCartData = cartData.map(
          (cartDish) =>
            temCartDishesWithCoverMap.get(cartDish.dishId + cartDish.index) ||
            cartDish
        );
        dispatch(setCartData(tempCartData));
      }
    }
  }, [cartData, dishes]);

  /**
   * 从购物车中移除一个菜品
   * @param dish
   */
  const removeOneDishFromCart = (dish: ICartDish, index: number) => {
    dispatch(deleteDishFromCart(tableId, dish, index));
  };

  /**
   * 是否打包的处理回调
   */
  const handlePackChecked = async () => {
    // 如果需要打包带走，检查购物车中是否有不可打包的菜品
    if (!packChecked) {
      const existNoPackDish = cartData.some((dishInCart) => {
        return !dishInCart.allowPacking;
      });
      if (existNoPackDish) {
        const result = await Dialog.confirm({
          content: (
            <div className="flex flex-col gap-2">
              <div>{t("app.cart.noPackTips.content1")}</div>
              <div>
                {t("app.cart.noPackTips.content2")}:{" "}
                <Tag color="warning" fill="outline" className="line-through">
                  Pack
                </Tag>
              </div>
            </div>
          ),
          confirmText: t("app.cart.dialog.confirmText"),
          cancelText: t("app.cart.dialog.cancelText"),
        });
        if (result) {
          setPackChecked(!packChecked);
        }
      } else {
        setPackChecked(!packChecked);
      }
    } else {
      setPackChecked(!packChecked);
    }
  };

  /**
   * 给厨师留言的输入框回调
   */
  const handleNoteChange = (value: string) => {
    if (value.length > 43) {
      setNote(value.substring(0, 40));
      Toast.show("超过最长限制!");
    } else {
      setNote(value);
    }
  };

  /**
   * 用户执行下单
   */
  const sendOrder = async () => {
    setLoading(true);
    const url = "/dish-order/placeOrder/" + tableId;

    const requestBody = packing
      ? { fromMerchant: 0, packing: true, note: note }
      : {
          fromMerchant: 0,
          packing: packChecked,
          note: note,
        };

    const response = await axiosInstance.post<ApiResponse>(url, requestBody);

    const apiResponse: ApiResponse = response.data;
    // 成功
    if (apiResponse.code === 201) {
      dispatch(emptyCartData()); // 清空购物车
      setLoading(false);
      setOrderPlaced(true);

      // TODO push an order
      dispatch(setCurrentOrders(apiResponse.data));

      // dispatch(passCurrentOrders(apiResponse.data)); // 传递返回的订单数据
    }
  };

  return (
    <div
      {...rightSwipHandler}
      className="w-screen h-screen flex flex-col justify-between gap-2 bg-[#F7F7F7] overflow-auto scrollbar-hidden"
    >
      <div className="flex flex-col gap-2">
        {/* 头部 */}
        <div
          className={
            "h-12 flex flex-row justify-between items-center gap-2 pr-4 bg-gradient-to-b from-[#FFD000] from-20% to-[#FAE47a]"
          }
        >
          {/* 返回 */}
          <NavBar
            onBack={() => {
              if (orderPlaced) {
                dispatch(setHomeActivePannel("order"));
                dispatch(setPage("home"));
              }
              dispatch(setPage(previousPage));
            }}
          ></NavBar>

          <div
            className="text-lg"
            onClick={() => {
              if (orderPlaced) {
                dispatch(setHomeActivePannel("order"));
                dispatch(setPage("home"));
              }
              dispatch(setPage(previousPage));
            }}
          >
            {setting.restaurantSetting.restaurantName}
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="flex items-center justify-end text-base">
              {packing ? (
                <Tag color="warning">Pack</Tag>
              ) : (
                <div className="flex flex-row items-center">
                  <div className="size-5">
                    <TableSVG fontSize={12} />
                  </div>
                  <span className="pl-1">{tableNumber}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {cartData.length === 0 && !orderPlaced ? (
          <div className="flex flex-col items-center mt-20 gap-4">
            <div>
              <ErrorBlock
                image={<FoodIcon />}
                style={{
                  "--image-height": "100px",
                }}
                title={t("app.cart.empty.title")}
                description={<span>{t("app.cart.empty.description")}</span>}
              ></ErrorBlock>
            </div>
            <div>
              <Button
                className="text-gray-500"
                onClick={() => {
                  dispatch(setPage("home"));
                  dispatch(setHomeActivePannel("home"));
                }}
              >
                {t("app.order.tipsOnButton")}
              </Button>
            </div>
          </div>
        ) : // 已经下单完成，显示结果
        cartData.length === 0 && orderPlaced ? (
          <div>
            <Result
              status="success"
              title={t("app.cart.orderSuccess.title")}
              description={
                <div className="flex flex-col">
                  <div>
                    {setting.diningSetting.payFirst
                      ? t("app.cart.orderSuccess.desc1")
                      : t("app.cart.orderSuccess.desc2")}
                  </div>
                  {currentOrders[0].packing ? (
                    <div className="text-xl mt-6 text-black">
                      {t("app.order.packingCode")}:{" "}
                      <span className="text-2xl">
                        {currentOrders[0].packingCode}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />

            {setting.diningSetting.payFirst ? (
              <div className="bg-white px-2 py-4 text-xl text-center">
                <AutoCancelOrderCountdown order={currentOrders[0]} />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          // 菜品列表
          <div
            {...rightSwipHandler}
            className="flex flex-col gap-2 mx-2 max-h-[400px] py-2 overflow-auto rounded-lg bg-white drop-shadow-sm"
          >
            {cartData.map((dish, index) => {
              return (
                <div key={index} className="flex flex-row gap-2">
                  <div className="flex items-start">
                    <Image
                      src={dish.imageCover}
                      className="size-20 rounded-md ml-2"
                    />
                  </div>
                  <div className="flex flex-col justify-between gap-2 grow mr-2">
                    <div className="flex flex-row justify-between text-base">
                      <div>{dish.dishName}</div>
                    </div>

                    <div className="flex flex-row gap-3">
                      <Tag color="#E5E7EB" className="text-gray-600">
                        {dish.dishWeight}
                      </Tag>
                      {dish.allowPacking ? (
                        <></>
                      ) : (
                        <div>
                          <Popover
                            content={t("app.detail.nopack")}
                            trigger="click"
                            placement="bottom"
                            defaultVisible
                          >
                            <Tag
                              color="warning"
                              fill="outline"
                              className="line-through"
                            >
                              Pack
                            </Tag>
                          </Popover>
                        </div>
                      )}
                    </div>
                    {dish.chosenSpecifications.length > 0 ? (
                      <div className="text-xs text-gray-400">
                        {dish.chosenSpecifications.map((cs, index) =>
                          index === 0 ? (
                            <span key={index}>
                              {cs.chosenItem.itemName}{" "}
                              {cs.chosenItem.itemPrice > 0 ? (
                                <span className="text-gray-300">|</span>
                              ) : (
                                ""
                              )}
                              <span className="text-[#FE4A34]">
                                {cs.chosenItem.itemPrice === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="text-xs"> +$</span>
                                    {""}
                                    <span>{cs.chosenItem.itemPrice}</span>
                                  </>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span key={index}>
                              , {cs.chosenItem.itemName}{" "}
                              {cs.chosenItem.itemPrice > 0 ? (
                                <span className="text-gray-300">|</span>
                              ) : (
                                ""
                              )}
                              <span className="text-[#FE4A34]">
                                {cs.chosenItem.itemPrice === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="text-xs"> +$</span>
                                    {""}
                                    <span>{cs.chosenItem.itemPrice}</span>
                                  </>
                                )}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="flex flex-col items-end justify-between mr-2">
                    <div>
                      <div className="text-[#FE4A34] text-lg">
                        <span className="text-xs">$</span>
                        {dish.discounting ? dish.preferentialPrice : dish.price}
                      </div>
                      {dish.discounting ? (
                        <div className="text-[#A4A1A1] text-sm flex items-center line-through justify-end">
                          <span className="text-xs">$</span>
                          {dish.price}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="mb-2"
                      onClick={() =>
                        removeOneDishFromCart(
                          dish as ICartDish,
                          dish.index as number
                        )
                      }
                    >
                      <DeleteOutline fontSize={18} className="text-gray-300" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {cartData.length > 0 ? (
          <div className="flex flex-col gap-2">
            {!packing ? (
              <div className="mx-2 px-2 text-base flex flex-row justify-between items-center bg-white h-14 rounded-lg drop-shadow-sm">
                <div>{t("app.cart.pack")}</div>
                <Switch
                  checkedText={<CheckOutline fontSize={14} />}
                  uncheckedText={<CloseOutline fontSize={14} />}
                  checked={packChecked}
                  onChange={() => handlePackChecked()}
                />
              </div>
            ) : (
              <></>
            )}

            <div className="flex flex-row text-base bg-white h-14 items-center rounded-lg drop-shadow-sm mx-2 p-2 gap-4">
              <div>{t("app.cart.note")}</div>
              <div className="text-sm grow">
                {/* 给厨师留言 &gt; */}
                <Input
                  placeholder={t("app.cart.message")}
                  clearable
                  style={{ "--text-align": "right", "--font-size": "14px" }}
                  className="grow"
                  value={note}
                  onChange={(noteValue) => {
                    handleNoteChange(noteValue);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* 底部 */}
      <div className="fixed bottom-0 right-0 w-full flex flex-col gap-1 grow-0">
        {/* 详情 */}
        <div className="bg-white mx-2 rounded-lg drop-shadow-sm gap-2 flex flex-col p-2 text-sm">
          <div className="flex flex-row justify-between">
            <div>{t("app.originalPrice")}:</div>
            <div className="text-[#A4A1A1] line-through">
              <span className="text-xs">$</span>
              {finalPrice + savedPrice}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div>{t("app.discount")}:</div>
            <div style={{ color: "var(--adm-color-warning)" }}>
              <span className="text-xs">$</span>
              {savedPrice}
            </div>
          </div>
          <Divider className="my-1" />
          <div className="flex flex-row justify-between">
            <div>{t("app.total")}:</div>
            <div className="font-medium">
              <span className="text-xs">$</span>
              {finalPrice}
            </div>
          </div>
        </div>

        {/* 最底部 */}
        <div className="h-16 bg-white">
          <div className="h-full flex flex-row justify-between items-center">
            <div className="flex flex-row gap-3 items-center">
              <div className="flex flex-row gap-2 ml-4 text-base font-semibold items-center">
                <div>{t("app.total")}</div>
                <div className="text-2xl text-[#FE4A34]">
                  <span className="text-xs">$</span>
                  {finalPrice}
                </div>
              </div>
              <div className="text-sm text-[#FE4A34]">
                {t("app.deduct")} <span className="text-xs">$</span>
                {savedPrice}
              </div>
            </div>

            <div className="mr-4 ">
              <Button
                style={{
                  lineHeight: "12px",
                }}
                className="h-9 px-4 text-sm"
                color="danger"
                shape="rounded"
                disabled={cartData.length === 0}
                loading={loading}
                onClick={() => {
                  sendOrder();
                }}
              >
                {t("app.cart.placeOrder")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
