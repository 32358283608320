import { Swiper, Tabs } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { SwiperRef } from "antd-mobile/es/components/swiper";
import TopDishes from "./topDishes";
import { HistogramOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import BestOffers from "./bestOffers";

interface ITabItem {
  key: string;
  title: string;
}

function Hot() {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);
  // 国际化
  const { t, ready } = useTranslation();

  const [tabItems, setTabItems] = useState<ITabItem[]>([]);

  useEffect(() => {
    if (ready && tabItems.length === 0) {
      setTabItems([
        { key: "topDishes", title: t("app.home.topDishes") },
        { key: "discount", title: t("app.home.offer") },
      ]);
    }
  }, [ready, tabItems]);

  return (
    <div
      className="h-full rounded-lg bg-white mt-0 pb-3 "
      style={{ "--adm-color-border": "transparent" } as React.CSSProperties}
    >
      <Tabs
        activeKey={tabItems[activeIndex]?.key}
        activeLineMode="fixed"
        style={{
          "--title-font-size": "18px",
          "--fixed-active-line-width": "30px",
        }}
        stretch={false}
        onChange={(key) => {
          const index = tabItems.findIndex((item) => item.key === key);
          setActiveIndex(index);
          swiperRef.current?.swipeTo(index);
        }}
      >
        {tabItems.map((item, index) => (
          <Tabs.Tab
            title={
              <div className="flex flex-row gap-1">
                {index === 0 ? <HistogramOutline fontSize={22} /> : <></>}{" "}
                <div>
                  {index === 0 ? t("app.home.topDishes") : t("app.home.offer")}
                </div>
              </div>
            }
            key={item.key}
          />
        ))}
      </Tabs>

      <Swiper
        direction="horizontal"
        loop
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={(index) => {
          setActiveIndex(index);
        }}
        className="w-full h-full rounded-lg"
      >
        <Swiper.Item>
          <TopDishes />
        </Swiper.Item>
        <Swiper.Item>
          <BestOffers />
        </Swiper.Item>
      </Swiper>
    </div>
  );
}

export default Hot;
