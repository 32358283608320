import {
  Badge,
  Button,
  FloatingBubble,
  Footer,
  Image,
  NavBar,
  Popover,
  Skeleton,
  Swiper,
  Tag,
} from "antd-mobile";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  setBeforePreviousPage,
  setDishIdInDetailPage,
  setPage,
} from "../../store/modules/windowSlice";
import { useSwipeable } from "react-swipeable";
import { DishImageType, IDish } from "../../definition/customTypes";
import { delay, loadDishImage } from "../../definition/utils";
import { useTranslation } from "react-i18next";
import {
  getAllDishData,
  replaceDishInDishes,
  updateImageCove,
} from "../../store/modules/dish/dishSlice";
import CartButton from "../../component/cartButton";
import { CartSmSVG } from "../../definition/customIcons";
import { setCartRefId } from "../../store/modules/itemDropSlice";
import { calculateFinalPrice } from "../../store/modules/cart/cartSlice";

function Detail() {
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 国际化
  const { t } = useTranslation();

  // 控制页面切换
  const { previousPage, dishIdInDetailPage } = useSelector(
    (state: RootState) => state.window
  );

  // 当前页面的菜品
  const [dishInDetailPage, setDishInDetailPage] = useState<IDish | null>(null);

  // 该菜品所属分类下的其他所有菜品
  const [otherDshesInCategory, setOtherDishesInCategory] = useState<IDish[]>(
    []
  );

  const { dishes } = useSelector((state: RootState) => state.dishes);

  // 购物车数据
  const { cartData, finalPrice, savedPrice } = useSelector(
    (state: RootState) => state.cart
  );

  // 当前菜品在购物车中的数量
  const [count, setCount] = useState<number>(0);

  /**
   * 监听页面右滑操作，若右滑则返回到 previousPage
   */
  const rightSwipHandler = useSwipeable({
    onSwipedRight: () => dispatch(setPage(previousPage)),
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  // 确保，剩余图片只加载一次，避免循环加载
  const [isDishImagesUpdated, setIsDishImagesUpdated] = useState(false);

  /**
   * 1. 若未初始化，则初始化加载所有 菜品 数据
   */
  useEffect(() => {
    if (dishes.length === 0) {
      dispatch(getAllDishData());
    }
  }, [dishes, dispatch]);

  /**
   * 2. 初始化 当前页面的菜品数据，并设置购物车的 id 名称
   */
  useEffect(() => {
    if (dishes.length > 0) {
      const tempDish = dishes.filter(
        (dish) => dish.dishId === dishIdInDetailPage
      );
      setDishInDetailPage(tempDish[0]);
      dispatch(setCartRefId("detailCartId"));
    }
  }, [dishIdInDetailPage, dishes]);

  /**
   * 计算购物车中的菜品数量
   */
  useEffect(() => {
    if (dishInDetailPage) {
      // 只有添加购物车时，延迟300毫秒，配合坠落动画。移除购物车时，不延迟
      if (count < cartData.length && count > 0) {
        delay(300).then(() => {
          setCount(cartData.length);
        });
      } else {
        setCount(cartData.length);
      }
    }
  }, [cartData, dishInDetailPage]);

  /**
   * 3. 加载剩余的图片
   */
  useEffect(() => {
    const reloadDishImages = async () => {
      let flag = false;
      const tempDishImages = dishInDetailPage?.dishImages as DishImageType[];
      if ((tempDishImages?.length as number) > 0) {
        const updatedDishImages = await Promise.all(
          tempDishImages?.map(async (imageObj) => {
            if (imageObj.url !== "") {
              return imageObj;
            } else {
              flag = true;
              const imageURL = await loadDishImage(imageObj.uid);
              return { ...imageObj, url: imageURL };
            }
          })
        );
        setIsDishImagesUpdated(true);
        if (!flag) return; // 如果没有图片更新，则返回。防止循环执行。
        const tempDishInDetailPage = {
          ...dishInDetailPage,
          dishImages: updatedDishImages,
          imageCover: updatedDishImages[0].url, // 同时更新 Cover
        };
        setDishInDetailPage(tempDishInDetailPage as IDish);
        dispatch(replaceDishInDishes(tempDishInDetailPage)); // 更新到 主 dishes
      }
    };
    if (!isDishImagesUpdated && dishInDetailPage) {
      reloadDishImages();
    }
  }, [dishInDetailPage, dispatch]);

  /**
   * 4. 查找出该菜品所属分类下的其他菜品
   */
  useEffect(() => {
    if (dishes.length > 0) {
      const tempDishes = dishes.filter(
        (dish) =>
          dish.categoryId === dishInDetailPage?.categoryId &&
          dish.dishId !== dishInDetailPage.dishId
      );
      if (tempDishes.length === 0) return;
      setOtherDishesInCategory(tempDishes);
    }
  }, [dishes, dishInDetailPage]);

  /**
   * 5. 更新其他菜品的封面图片
   */
  useEffect(() => {
    if (otherDshesInCategory.length > 0) {
      const dishesNoCover = otherDshesInCategory.filter(
        (dish) => !dish.imageCover
      );
      if (dishesNoCover.length === 0) {
        return;
      }
      dispatch(updateImageCove(dishesNoCover));
    }
  }, [otherDshesInCategory]);

  /**
   * 购物车数据变化时，重新计算价格
   */
  useEffect(() => {
    dispatch(calculateFinalPrice());
  }, [cartData]);

  return (
    <div className="h-screen flex flex-col justify-between gap-2">
      <div className="bg-[#F7F7F7] ">
        <NavBar
          onBack={() => dispatch(setPage(previousPage))}
          className="bg-white"
        >
          {dishInDetailPage?.dishName}
        </NavBar>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <Swiper
            loop
            indicator={(total, current) => (
              <div className={"customIndicator"}>{`${
                current + 1
              } / ${total}`}</div>
            )}
            className="w-full h-full rounded-lg drop-shadow"
          >
            {(dishInDetailPage?.dishImages.length as number) > 0 ? (
              dishInDetailPage?.dishImages.map((img, index) => {
                return (
                  <Swiper.Item key={index}>
                    <Image
                      src={img.url}
                      fit="cover"
                      className="h-44 rounded-lg"
                    />
                  </Swiper.Item>
                );
              })
            ) : (
              <Swiper.Item>
                <Skeleton animated className="h-44 rounded-lg" />
              </Swiper.Item>
            )}
          </Swiper>
          <div {...rightSwipHandler} className="flex flex-col gap-2">
            {/* 价格和加购部分 */}
            <div className="flex flex-col gap-2 bg-white pb-2 px-2 rounded-md drop-shadow-sm">
              <div className="grid grid-rows-1 grid-flow-col gap-4 mt-1">
                <div className="text-left text-[#FE4A34] text-lg">
                  <span className="text-xs">$</span>
                  {dishInDetailPage?.discounting
                    ? dishInDetailPage.preferentialPrice
                    : dishInDetailPage?.price}
                </div>
                {dishInDetailPage?.dishStatus === "ONLINE" ? (
                  <div className="text-right text-[#A4A1A1]">
                    {t("app.detail.stock.inStock")}
                  </div>
                ) : dishInDetailPage?.dishStatus === "SOLD_OUT" ? (
                  <div className="text-right">
                    <Tag color="danger" className="rounded-lg py-1">
                      {t("app.detail.stock.soldOut")}
                    </Tag>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {dishInDetailPage?.discounting ? (
                <div>
                  <div className="text-[#A4A1A1] text-sm flex items-center">
                    <div className="line-through mr-2">
                      <span className="text-xs">$</span>
                      {dishInDetailPage.price}
                    </div>

                    <Tag color="danger" fill="outline">
                      {t("app.home.save")} $
                      {dishInDetailPage.price -
                        dishInDetailPage.preferentialPrice}
                    </Tag>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="flex flex-row gap-4 items-center">
                <div className="text-lg font-medium">
                  {dishInDetailPage?.dishName}
                </div>
                {dishInDetailPage?.allowPacking ? (
                  <></>
                ) : (
                  <div>
                    <Popover
                      content={t("app.detail.nopack")}
                      trigger="click"
                      placement="bottom"
                      defaultVisible
                    >
                      <Tag
                        color="warning"
                        fill="outline"
                        className="line-through"
                      >
                        Pack
                      </Tag>
                    </Popover>
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-gray-500">
                  {dishInDetailPage?.servedDishes ? (
                    <>
                      <span>{t("app.mSale")} </span>
                      <span>{dishInDetailPage?.servedDishes}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <CartButton
                    currentDish={dishInDetailPage as IDish}
                    isDetail={true}
                    idPrefix="detail"
                    chosenSpecifications={[]}
                  />
                </div>
              </div>
            </div>

            {/* 详情部分 */}
            <div className="flex flex-col gap-2 bg-white p-2 rounded-md drop-shadow-sm">
              <div className="text-base font-medium">
                {t("app.detail.details")}:
              </div>
              <div className="flex flex-row gap-4">
                <div className="w-20 text-[#A4A1A1] flex-none">
                  {t("app.detail.desc")}:
                </div>
                <div>{dishInDetailPage?.description}</div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="w-20 flex-none text-[#A4A1A1]">
                  {t("app.detail.ingredients")}:
                </div>
                <div>
                  {dishInDetailPage?.mainIngredients.map((item, index) => {
                    return index !== 0 ? (
                      <span key={index}>, {item}</span>
                    ) : (
                      <span key={index}>{item}</span>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="w-20 flex-none text-[#A4A1A1]">
                  {t("app.detail.dishWeight")}:
                </div>
                <div>{dishInDetailPage?.dishWeight}</div>
              </div>
            </div>

            {/* 同类菜品部分 */}
            {otherDshesInCategory.length > 0 ? (
              <div className="flex flex-col gap-2 bg-white p-2 rounded-md drop-shadow-sm ">
                <div className="text-base font-medium">
                  {t("app.detail.otherDishes")}:
                </div>
                {otherDshesInCategory
                  // .filter((dish) => dish.dishId !== dishIdInDetailPage) // 过滤掉 自身菜品
                  .map((dish, index) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-row justify-between w-full"
                      >
                        <div className="flex flex-row gap-2 w-full">
                          <div>
                            {dish.dishStatus === "SOLD_OUT" ? (
                              <Badge
                                content={t("app.detail.stock.soldOut")}
                                style={{ "--right": "23%", "--top": "10%" }}
                                className="py-1"
                              >
                                <Image
                                  src={dish.imageCover}
                                  fit="cover"
                                  className="size-32 rounded-lg"
                                  onClick={() => {
                                    dispatch(
                                      setDishIdInDetailPage(dish.dishId)
                                    );
                                    setIsDishImagesUpdated(false);
                                  }}
                                />
                              </Badge>
                            ) : (
                              <Image
                                src={dish.imageCover}
                                fit="cover"
                                className="size-32 rounded-lg"
                                onClick={() => {
                                  dispatch(setDishIdInDetailPage(dish.dishId));
                                  setIsDishImagesUpdated(false);
                                }}
                              />
                            )}
                          </div>

                          <div className="flex flex-col justify-between grow">
                            <div
                              className="text-base"
                              onClick={() => {
                                dispatch(setDishIdInDetailPage(dish.dishId));
                                setIsDishImagesUpdated(false);
                              }}
                            >
                              {dish.dishName}
                            </div>
                            <div className="flex flex-row gap-6 mt-2">
                              <div>
                                <Tag color="#E5E7EB" className="text-gray-600">
                                  {dish.dishWeight}
                                </Tag>
                              </div>
                            </div>
                            <div className="flex flex-row gap-3">
                              <div className="text-[#A4A1A1]">
                                {t("app.detail.ingredients")}:
                              </div>
                              <div>
                                {dish.mainIngredients.map((item, index) => {
                                  return index !== 0 ? (
                                    <span key={index}>, {item}</span>
                                  ) : (
                                    <span key={index}>{item}</span>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full">
                              <div className="text-[#FE4A34] text-lg mt-2 flex flex-row gap-2">
                                <div>
                                  <span className="text-xs">$</span>
                                  {dish.discounting
                                    ? dish.preferentialPrice
                                    : dish.price}
                                </div>
                                {dish.discounting ? (
                                  <div className="text-[#A4A1A1] text-sm flex items-center line-through">
                                    <span className="text-xs">$</span>
                                    {dish.price}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="mr-2 flex items-end pb-2">
                                <CartButton
                                  currentDish={dish as IDish}
                                  isDetail={false}
                                  idPrefix="detail"
                                  chosenSpecifications={[]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <FloatingBubble
          axis="x"
          magnetic="x"
          style={{
            "--initial-position-bottom": "272px",
            "--initial-position-right": "14px",
            "--edge-distance": "24px",
            "--background": "#09CC43",
            "--size": "44px",
          }}
          className="shadow-lg opacity-80"
          onClick={() => {
            dispatch(setBeforePreviousPage());
            dispatch(setPage("cart"));
          }}
        >
          {count > 0 ? (
            <Badge
              content={count}
              style={{ "--right": "25%", "--top": "25%" }}
              className="p-0.5"
            >
              <div
                id="detailCartId"
                className="flex flex-col size-9 justify-center items-center bg-white rounded-full"
              >
                <CartSmSVG color="#09CC43" fontSize={32} />
              </div>
            </Badge>
          ) : (
            <div
              id="detailCartId"
              className="flex flex-col size-9 justify-center items-center bg-white rounded-full"
            >
              <CartSmSVG color="#09CC43" fontSize={32} />
            </div>
          )}
        </FloatingBubble>
        <Footer
          label={t("app.home.endPage")}
          className="w-full h-40 bg-transparent"
        ></Footer>
      </div>

      {/* 最底部 */}
      <div className="h-16 fixed bottom-0 right-0 w-full grow-0 bg-white">
        <div className="h-full flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3 items-center">
            <div className="flex flex-row gap-2 ml-4 text-base font-semibold items-center">
              <div>{t("app.total")}</div>
              <div className="text-2xl text-[#FE4A34]">
                <span className="text-xs">$</span>
                {finalPrice}
              </div>
            </div>
            <div className="text-sm text-[#FE4A34]">
              共减 <span className="text-xs">$</span>
              {savedPrice}
            </div>
          </div>

          <div className="mr-4 ">
            <Button
              style={{
                lineHeight: "12px",
              }}
              className="h-9 w-28 text-sm"
              color="danger"
              shape="rounded"
              disabled={cartData.length === 0}
              onClick={() => {
                dispatch(setBeforePreviousPage());
                dispatch(setPage("cart"));
              }}
            >
              去下单
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
