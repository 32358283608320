import { createSlice } from "@reduxjs/toolkit";
import { ApiResponse, IDish } from "../../../definition/customTypes";
import { AppDispatch } from "../..";
import axiosInstance from "../../../definition/request";
import { loadDishImage } from "../../../definition/utils";

interface ICarousel {
  carousel: IDish[];
  isDataReady: boolean;
  isImageCoverLoaded: boolean;
}

const initialState: ICarousel = {
  carousel: [],
  isDataReady: false,
  isImageCoverLoaded: false,
};
/**
 * 首页轮播
 */
const carouselSlice = createSlice({
  name: "carousel",
  initialState: initialState,
  reducers: {
    setCarousel(state, action) {
      state.carousel = action.payload;
    },
    setIsDataReady(state, action) {
      state.isDataReady = action.payload;
    },
    setIsImageCoverLoaded(state, action) {
      state.isImageCoverLoaded = action.payload;
    },
    /** 替换一个指定的菜品，当该菜品的所有图片在详情页被加载后，将Dish数据同步到这里 */
    replaceDishInCarousel(state, action) {
      const updatedDish = action.payload;
      const tempDishes = state.carousel.map((dish) => {
        if (dish.dishId === updatedDish.dishId) {
          return updatedDish;
        } else {
          return dish;
        }
      });
      state.carousel = tempDishes;
    },
  },
});

const { setCarousel, setIsDataReady, setIsImageCoverLoaded, replaceDishInCarousel } =
  carouselSlice.actions;

const reducer = carouselSlice.reducer;

/**
 *
 * @returns 初始化首页轮播数据
 */
const initCarouselData = () => {
  return async (dispatch: AppDispatch) => {
    const url = "/menu-factory/getHomeCarouselDishes";
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;
      // 成功
      if (apiResponse.code === 200) {
        const carouseDishes: IDish[] = apiResponse.data;
        dispatch(setCarousel(carouseDishes));
        dispatch(setIsDataReady(true));
      }
      // 失败
      else if (apiResponse.code === 500) {
        // message.error(apiResponse.msg);
      }
    });
  };
};

/**
 *
 * @param carousel 更新封面图片, 并把更新后的图片地址放入到 dishImages中去。
 */
const updateImageCove = (carousel: IDish[]) => {
  return async (dispatch: AppDispatch) => {
    const tempCarousel = await Promise.all(
      carousel.map(async (dish) => {
        const tempDishImages = [...dish.dishImages];
        const imageURL = await loadDishImage(tempDishImages[0].uid);
        const updatedDishImages = [...tempDishImages].map((imageObj, i) => {
          if (i === 0) {
            return { ...imageObj, url: imageURL };
          } else {
            return imageObj;
          }
        });
        return { ...dish, imageCover: imageURL, dishImages: updatedDishImages };
      })
    );
    dispatch(setCarousel(tempCarousel));
    dispatch(setIsImageCoverLoaded(true));
  };
};

export { setCarousel, initCarouselData, updateImageCove, replaceDishInCarousel };
export default reducer;
