import React, {
  memo,
  ReactElement,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Button, Footer, Tag } from "antd-mobile";
import { Constant } from "../../definition/config";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { TableSVG } from "../../definition/customIcons";
import { useTranslation } from "react-i18next";
import Carousel from "./carousel/carousel";
import Recommend from "./recommend/recommend";
import { switchLanguage } from "../../store/modules/lang/langSlice";
import Hot from "./hot/hot";

interface IProps {
  tableNumber: number;
}

const Home = memo(function (props: IProps) {
  const { tableNumber } = props;

  // 国际化
  const { t } = useTranslation();

  // 用户选择好的 偏好语言, 语言 选项
  const { chosenLanguage, availableLanguages } = useSelector(
    (state: RootState) => state.lang
  );

  // 用户偏好语言之外的，另一种语言
  const [otherLanguage, setOtherLanguage] = useState("");

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 是否通过打包带走的专用二维码进来的
  const { packing } = useSelector((state: RootState) => state.window);

  /**
   * 提取除用户偏好语言之外的，另一种语言
   */
  useEffect(() => {
    const otherLan = availableLanguages.filter((lan) => lan !== chosenLanguage);
    setOtherLanguage(otherLan[0]);
  }, [chosenLanguage, availableLanguages]);

  return (
    <div className="w-full h-screen flex flex-col gap-2 bg-[#F7F7F7] overflow-auto scrollbar-hidden">
      <div
        className={
          "w-full h-[80px] flex flex-col justify-start gap-2 px-4 py-1 bg-gradient-to-b from-[#FFD000] from-20% to-[#FAE47a]"
        }
      >
        <div className="w-full flex flex-row justify-between">
          <div className="text-lg">
            {setting.restaurantSetting.restaurantName}
          </div>
          <div className="text-sm">
            <Button
              size="small"
              fill="none"
              onClick={() => {
                dispatch(switchLanguage(otherLanguage));
              }}
            >
              <span>
                {
                  Constant.I18N_TEXT_MAP[
                    otherLanguage as keyof typeof Constant.I18N_TEXT_MAP
                  ]
                }
              </span>
            </Button>
          </div>
        </div>
        <div className="w-full flex flex-row justify-start">
          {/* <div className="bg-white w-64 h-8 basis-4/5 rounded-2xl flex items-center flex-row">
            <div className="mx-2">
              <SearchOutline fontSize={20} />
            </div>
            <Input
              placeholder={t("app.search.placeholder")}
              clearable
              className="custom-input"
              //   value={value}
              //   onChange={(val) => {
              //     setValue(val);
              //   }}
            />
          </div> */}
          <div className="flex items-center justify-end text-base">
            {packing ? (
              <Tag color="warning">Pack</Tag>
            ) : (
              <div className="flex flex-row items-center">
                <div className="size-5">
                  <TableSVG fontSize={12} />
                </div>
                <span className="pl-1">{tableNumber}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-32 mx-2 drop-shadow-sm">
        <Carousel />
      </div>
      <div className="mx-2 drop-shadow-sm">
        <Recommend />
      </div>
      <div className="mx-2 drop-shadow-sm">
        <Hot />
      </div>
      <div className="h-48 w-full flex flex-row justify-center items-center">
        <Footer
          label={t("app.home.endPage")}
          content="@ 2004-2025 BiteVerse All rights reserved"
          className="w-full h-36"
        ></Footer>
      </div>
    </div>
  );
});

export default Home;
