import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../index";
import axiosInstance from "../../../definition/request";
import { ApiResponse } from "../../../definition/customTypes";
import { IDish } from "../../../definition/customTypes";
import { loadDishThumbnail } from "../../../definition/utils";

/**
 * Slice 中的数据结构规范
 */
interface IDishSlice {
  dishes: IDish[];
}

/**
 * Slice 中数据的初始状态
 */
const initialState: IDishSlice = {
  /** 缓存所有菜品的数据集 */
  dishes: [],
};

const dishSlice = createSlice({
  name: "dish",
  initialState: initialState,
  reducers: {
    setDishes(state, action) {
      const dishes = [...action.payload].map((dish) => {
        return { ...dish, dishId: dish.id };
      });
      state.dishes = dishes;
    },

    /** 更新一些菜品 */
    replaceSomeDishes(state, action) {
      const newDishes = action.payload;
      const newDishesMap = new Map(
        newDishes.map((dish: IDish) => [dish.dishId, dish])
      );
      const tempDishes = [...state.dishes].map(
        (dish) => (newDishesMap.get(dish.dishId) || dish) as IDish
      );
      state.dishes = tempDishes;
    },

    /** 替换一个指定的菜品，当该菜品的所有图片在详情页被加载后，将Dish数据同步到这里 */
    replaceDishInDishes(state, action) {
      const updatedDish = action.payload;
      const tempDishes = state.dishes.map((dish) => {
        if (dish.dishId === updatedDish.dishId) {
          return updatedDish;
        } else {
          return dish;
        }
      });
      state.dishes = tempDishes;
    },
  },
});

const { setDishes, replaceSomeDishes, replaceDishInDishes } = dishSlice.actions;
const reducer = dishSlice.reducer;

/**
 * 获取所有的 菜品列表
 * @returns
 */
const getAllDishData = () => {
  return (dispatch: AppDispatch) => {
    const url: string = "/menu-factory/getAllAvailableDishData";

    axiosInstance
      .get<ApiResponse>(url)
      .then((response) => {
        const apiResponse: ApiResponse = response.data;
        // 成功，
        if (apiResponse.code === 200) {
          if (apiResponse.data.length === 0) return; // 如果菜品数据为空，则直接返回，不需要设置。防止页面无限请求。
          dispatch(setDishes(apiResponse.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Response error:", error.response.data);
          console.log("Error status:", error.response.status);
        } else {
          console.error("Other error:", error);
        }
      });
  };
};

/**
 *
 * @param dishes 更新封面图片
 */
const updateImageCove = (dishes: IDish[]) => {
  return async (dispatch: AppDispatch) => {
    const tempDishes = await Promise.all(
      dishes.map(async (dish) => {
        const imageURL = await loadDishThumbnail(dish.dishImages[0].uid);
        return { ...dish, imageCover: imageURL };
      })
    );
    dispatch(replaceSomeDishes(tempDishes));
  };
};

export { getAllDishData, updateImageCove, replaceDishInDishes };
export default reducer;
