import React, { RefObject, useEffect, useRef, useState } from "react";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { setIsAnimating } from "../store/modules/itemDropSlice";
import { Image } from "antd-mobile";

type DropPosition = {
  top: number;
  left: number;
};

/**
 * 核心组件：添加购物车时，显示菜品的坠落动画
 * @returns
 */
function DishDropIntoCart() {
  const dropRef = useRef<HTMLDivElement | null>(null);

  const [startPosition, setStartPosition] = useState<DropPosition>({
    top: 0,
    left: 0,
  });
  const [endPosition, setEndPosition] = useState<DropPosition>({
    top: 0,
    left: 0,
  });

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 保存到 Redux 中的 merchantId, index.tsx 中初始化
  const { isAnimating, cartRefId, dishRefId, imgURL } = useSelector(
    (state: RootState) => state.itemDrop
  );

  useEffect(() => {
    if (isAnimating && dishRefId !== "" && cartRefId !== "") {
      const dishElement = document.getElementById(dishRefId);
      if (!dishElement) return;

      // 获取商品的初始位置
      const dishRect = dishElement.getBoundingClientRect();
      const startPosition = {
        top: (dishRect?.top as number) + window.scrollY,
        left: (dishRect?.left as number) + window.scrollX,
      };
      setStartPosition(startPosition);
      // console.log("startPosition = ", startPosition);

      // 获取购物车按钮的位置
      const cartElement = document.getElementById(cartRefId);

      if (!cartElement) return;
      const cartRect = cartElement.getBoundingClientRect();

      const endPosition = {
        top: cartRect?.top as number,
        left: cartRect?.left as number,
      };
      setEndPosition(endPosition);
      // console.log("endPosition = ", endPosition);

      const dropElement = dropRef.current;
      if (!dropElement) return;

      // 动画结束后的回调
      const animationEndHandler = () => {
        dispatch(setIsAnimating(false));
      };

      dropElement.addEventListener("animationend", animationEndHandler);

      dropElement.style.animation =
        "dropAnimation 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards";

      return () => {
        dropElement.removeEventListener("animationend", animationEndHandler);
      };
    }
  }, [isAnimating, dishRefId, cartRefId]);

  return (
    <div>
      {isAnimating && (
        <div
          ref={dropRef}
          style={{
            position: "absolute",
            top: startPosition.top + "px", // 初始位置
            left: startPosition.left + "px",
            zIndex: 100000,
            animation:
              "dropAnimation 1s cubic-bezier(0.25, 1, 0.5, 1) forwards",
          }}
          className="z-[1000000] size-8"
        >
          <Image src={imgURL} fit="cover" className="size-7 rounded-2xl" />
        </div>
      )}

      <style>
        {`
          @keyframes dropAnimation {
            0% {
              transform: translate(0, 0);  /* 初始位置 */
              opacity: 1;
            }
            50% {
                transform: translate(-50px, -30px); /* 模拟抛物线的中间位置 */
              opacity: 0.7;
            }
              60% {
              opacity: 0.5;
            }
            100% {
              transform: translate(${
                endPosition.left - startPosition.left
              }px, ${endPosition.top - startPosition.top}px); /* 目标位置 */
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
}

export default DishDropIntoCart;
