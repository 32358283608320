import { createSlice } from "@reduxjs/toolkit";
import { ApiResponse, IDish } from "../../../definition/customTypes";
import { AppDispatch } from "../..";
import axiosInstance from "../../../definition/request";
import { loadDishImage, loadDishThumbnail } from "../../../definition/utils";

interface ITopDishes {
  topDishes: IDish[];
  isDataReady: boolean;
  isImageCoverLoaded: boolean;
}

const initialState: ITopDishes = {
  topDishes: [],
  isDataReady: false,
  isImageCoverLoaded: false,
};

/**
 * 月度榜单 菜品
 */
const topDishesSlice = createSlice({
  name: "topDishes",
  initialState: initialState,
  reducers: {
    setTopDishes(state, action) {
      state.topDishes = action.payload;
    },
    setIsDataReady(state, action) {
      state.isDataReady = action.payload;
    },
    setIsImageCoverLoaded(state, action) {
      state.isImageCoverLoaded = action.payload;
    },
    /** 替换一个指定的菜品，当该菜品的所有图片在详情页被加载后，将Dish数据同步到这里 */
    replaceDishInTopDishes(state, action) {
      const updatedDish = action.payload;
      const tempDishes = state.topDishes.map((dish) => {
        if (dish.dishId === updatedDish.dishId) {
          return updatedDish;
        } else {
          return dish;
        }
      });
      state.topDishes = tempDishes;
    },
  },
});

const {
  setTopDishes,
  setIsDataReady,
  setIsImageCoverLoaded,
  replaceDishInTopDishes,
} = topDishesSlice.actions;

const reducer = topDishesSlice.reducer;

/**
 *
 * @returns 初始化 月度榜单菜品数据
 */
const initTopDishesData = () => {
  return async (dispatch: AppDispatch) => {
    const url = "/ai-insight/getMonthlyTopDishes";
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;
      // 成功
      if (apiResponse.code === 200) {
        const topDishes: IDish[] = apiResponse.data;
        dispatch(setTopDishes(topDishes));
        dispatch(setIsDataReady(true));
      }
      // 失败
      else if (apiResponse.code === 500) {
        // message.error(apiResponse.msg);
      }
    });
  };
};

/**
 *
 * @param topDishes 更新封面图片
 */
const updateImageCove = (topDishes: IDish[]) => {
  return async (dispatch: AppDispatch) => {
    const tempTopDishes = await Promise.all(
      topDishes.map(async (dish) => {
        // const imageURL = await loadDishImage(dish.dishImages[0].uid);
        const imageURL = await loadDishThumbnail(dish.dishImages[0].uid);

        return { ...dish, imageCover: imageURL };
      })
    );
    dispatch(setTopDishes(tempTopDishes));
    dispatch(setIsImageCoverLoaded(true));
  };
};

export { initTopDishesData, updateImageCove, replaceDishInTopDishes };
export default reducer;
