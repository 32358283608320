import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  initTopDishesData,
  updateImageCove,
} from "../../../store/modules/topDishes/topDishesSlice";
import { Badge, Button, Image, Skeleton, Tag } from "antd-mobile";
import { useTranslation } from "react-i18next";
import {
  setDishIdInDetailPage,
  setPage,
} from "../../../store/modules/windowSlice";
import CartButton from "../../../component/cartButton";

function TopDishes() {
  // 国际化
  const { t } = useTranslation();
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 保存到 Redux 中的 merchantId, index.tsx 中初始化
  const { merchantId } = useSelector((state: RootState) => state.lang);

  const { topDishes, isDataReady, isImageCoverLoaded } = useSelector(
    (state: RootState) => state.topDishes
  );

  const { token } = useSelector((state: RootState) => state.window);

  const topDishesPlaceholder = [1, 2, 3, 4, 5, 6];

  /**
   * 加载 月度榜单菜品数据
   */
  useEffect(() => {
    if (topDishes.length === 0 && merchantId !== "" && token !== "") {
      dispatch(initTopDishesData());
    }
  }, [topDishes, merchantId, token, dispatch]);

  /**
   * 加载 月度排行图片
   */
  useEffect(() => {
    if (isDataReady && !isImageCoverLoaded) {
      //   console.log(topDishes);
      dispatch(updateImageCove(topDishes));
    }
  }, [isDataReady, isImageCoverLoaded, dispatch]);

  return (
    <div className="flex flex-col gap-2 mt-2 mx-2">
      {isDataReady
        ? topDishes.map((dish, index) => {
            return (
              <div key={index} className="flex flex-row justify-between">
                <div className="flex flex-row gap-2 grow">
                  {dish.dishStatus === "SOLD_OUT" ? (
                    <Badge
                      content={t("app.detail.stock.soldOut")}
                      style={{ "--right": "23%", "--top": "10%" }}
                      className="py-1"
                    >
                      <Image
                        src={dish.imageCover}
                        fit="cover"
                        className="size-32 rounded-lg"
                        onClick={() => {
                          dispatch(setDishIdInDetailPage(dish.dishId));
                          dispatch(setPage("detail"));
                        }}
                      />
                    </Badge>
                  ) : (
                    <Image
                      src={dish.imageCover}
                      fit="cover"
                      className="size-32 rounded-lg"
                      onClick={() => {
                        dispatch(setDishIdInDetailPage(dish.dishId));
                        dispatch(setPage("detail"));
                      }}
                    />
                  )}

                  <div className="grow">
                    <div
                      className="text-base"
                      onClick={() => {
                        dispatch(setDishIdInDetailPage(dish.dishId));
                        dispatch(setPage("detail"));
                      }}
                    >
                      {dish.dishName}
                    </div>
                    <div className="flex flex-row gap-6 mt-2">
                      <div className="text-gray-500">
                        <span>{t("app.mSale")} </span>
                        <span>{dish.servedDishes}</span>
                      </div>
                      <div>
                        <Tag color="#E5E7EB" className="text-gray-600">
                          {dish.dishWeight}
                        </Tag>
                      </div>
                    </div>
                    <div className="my-2 inline-block text-[#F0953F] bg-[#FFF9F0] py-1 px-2 rounded-md drop-shadow-none filter-none">
                      {t("app.rank")} {index + 1}
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className="text-[#FE4A34] text-lg mt-2 flex flex-row gap-2">
                        <div>
                          <span className="text-xs">$</span>
                          {dish.discounting
                            ? dish.preferentialPrice
                            : dish.price}
                        </div>
                        {dish.discounting ? (
                          <div className="text-[#A4A1A1] text-sm flex items-center line-through">
                            <span className="text-xs">$</span>
                            {dish.price}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mr-2 flex items-end pb-2">
                        <CartButton
                          currentDish={dish}
                          isDetail={false}
                          idPrefix="topDishes"
                          chosenSpecifications={[]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : topDishesPlaceholder.map((_, index) => (
            <div key={index} className="flex flex-row gap-2">
              <div>
                {/* 骨架图片 */}
                <Skeleton
                  animated
                  style={{
                    borderRadius: "8px",
                  }}
                  className="size-32"
                />
              </div>
              <div className="basis-1/2 ">
                <Skeleton.Title
                  animated
                  style={{
                    marginTop: "3px",
                    height: "26px",
                    borderRadius: "4px",
                  }}
                />
                <Skeleton.Paragraph
                  lineCount={2}
                  animated
                  style={{
                    marginTop: "3px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            </div>
          ))}
    </div>
  );
}

export default TopDishes;
