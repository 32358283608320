import React, { useEffect, useState } from "react";
import { Order } from "../definition/customTypes";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface IProps {
  order: Order;
}

/**
 * 对于先支付后就餐的餐厅，显示倒计时
 * @returns
 */
function AutoCancelOrderCountdown(props: IProps) {
  const { order } = props;

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 国际化
  const { t } = useTranslation();

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 倒计时时间
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    if (!order?.orderDate) return;

    const orderTime = new Date(order.orderDate).getTime(); // 下单时间（毫秒）
    const expirationTime =
      orderTime + setting.diningSetting.autoCancelOrderDelayMinutes * 60 * 1000; // 订单超时时间（毫秒）
    const currentTime = Date.now(); // 当前时间（毫秒）

    const remainingTime = Math.floor((expirationTime - currentTime) / 1000); // 剩余秒数

    setTimeLeft(remainingTime);

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => clearInterval(timer); // 组件卸载时清除定时器
  }, [order, setting]);

  // 格式化时间为 mm:ss
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  if (timeLeft <= 0)
    return (
      <div className="flex flex-col">
        <div>{t("app.order.closeText")}</div>
      </div>
    );
  else
    return (
      <div>
        {t("app.order.paymentCountdown", { timeLeft: formatTime(timeLeft) })}
      </div>
    );
}

export default AutoCancelOrderCountdown;
