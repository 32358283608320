import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";
import { IDish } from "../../definition/customTypes";

/**
 * vWidth: 视图的宽度，在App组件中监听并动态获取
 * page: 页面类型；
 *    默认为首页 home: 带4个吸底的底部导航栏；
 *    detai: 菜品详情页
 *    cart: 购物车页
 * previousPage: 切换页面时，保存切换之前的页面。用以返回时能够返回到之前的页面
 */
interface IWindow {
  vWidth: number;
  page: "detail" | "home" | "cart";
  previousPage: "detail" | "home" | "cart";
  beforePreviousPage: "detail" | "home" | "cart" | "";
  tableId: string;
  tableNumber: number;
  packing: boolean | null;
  token: string;
  dishIdInDetailPage: string;
  homeActivePannel: "home" | "category" | "cart" | "order"; // 首页底部的激活面板
}

const initialState: IWindow = {
  vWidth: 0,
  page: "home",
  previousPage: "home",
  beforePreviousPage: "",
  tableId: "0",
  tableNumber: -1,
  packing: null,
  token: "",
  dishIdInDetailPage: "",
  homeActivePannel: "home",
};

const windowSlice = createSlice({
  name: "window",
  initialState: initialState,
  reducers: {
    setVwidth(state, action) {
      state.vWidth = action.payload;
    },
    setPage(state, action) {
      if (state.beforePreviousPage !== "" && state.previousPage === "detail") {
        state.previousPage = state.beforePreviousPage;
      } else {
        state.previousPage = state.page;
        if (action.payload !== "cart") {
          state.beforePreviousPage = "";
        }
      }

      state.page = action.payload;
    },
    setBeforePreviousPage(state) {
      state.beforePreviousPage = state.previousPage;
    },
    setHomeActivePannel(state, action) {
      state.homeActivePannel = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setTableNumber(state, action) {
      state.tableNumber = action.payload;
    },
    setTableId(state, action) {
      state.tableId = action.payload;
    },
    setPacking(state, action) {
      state.packing = action.payload;
    },
    setDishIdInDetailPage(state, action) {
      state.dishIdInDetailPage = action.payload;
    },
  },
});

const {
  setVwidth,
  setPage,
  setBeforePreviousPage,
  setToken,
  setTableNumber,
  setTableId,
  setPacking,
  setDishIdInDetailPage,
  setHomeActivePannel,
} = windowSlice.actions;

const reducer = windowSlice.reducer;

export {
  setVwidth,
  setPage,
  setBeforePreviousPage,
  setToken,
  setTableNumber,
  setTableId,
  setPacking,
  setDishIdInDetailPage,
  setHomeActivePannel,
};
export default reducer;
