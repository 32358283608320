import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../index";
import axiosInstance from "../../../definition/request";
import { ApiResponse } from "../../../definition/customTypes";
import { IDish } from "../../../definition/customTypes";
import { loadDishThumbnail } from "../../../definition/utils";

/**
 * Slice 中的数据结构规范
 */
interface ISpecificationSlice {
  currentDish: IDish | null; // 当前菜品
  showSpecificationModal: boolean;
}

/**
 * Slice 中数据的初始状态
 */
const initialState: ISpecificationSlice = {
  currentDish: null,
  showSpecificationModal: false,
};

const specificationSlice = createSlice({
  name: "specification",
  initialState: initialState,
  reducers: {
    setCurrentDish(state, action) {
      state.currentDish = action.payload;
    },
    setShowSpecificationModal(state, action) {
      state.showSpecificationModal = action.payload;
    },
  },
});

const { setCurrentDish, setShowSpecificationModal } =
  specificationSlice.actions;
const reducer = specificationSlice.reducer;

export { setCurrentDish, setShowSpecificationModal };
export default reducer;
