import { createSlice } from "@reduxjs/toolkit";
import { ApiResponse, IDish } from "../../../definition/customTypes";
import { AppDispatch } from "../..";
import axiosInstance from "../../../definition/request";
import { loadDishImage } from "../../../definition/utils";

interface IRecommend {
  recommend: IDish[];
  isDataReady: boolean;
  isImageCoverLoaded: boolean;
}

const initialState: IRecommend = {
  recommend: [],
  isDataReady: false,
  isImageCoverLoaded: false,
};
/**
 * 首页轮播
 */
const recommendlSlice = createSlice({
  name: "recommend",
  initialState: initialState,
  reducers: {
    setRecommend(state, action) {
      state.recommend = action.payload;
    },
    setIsDataReady(state, action) {
      state.isDataReady = action.payload;
    },
    setIsImageCoverLoaded(state, action) {
      state.isImageCoverLoaded = action.payload;
    },
    /** 替换一个指定的菜品，当该菜品的所有图片在详情页被加载后，将Dish数据同步到这里 */
    replaceDishInRecommend(state, action) {
      const updatedDish = action.payload;
      const tempDishes = state.recommend.map((dish) => {
        if (dish.dishId === updatedDish.dishId) {
          return updatedDish;
        } else {
          return dish;
        }
      });
      state.recommend = tempDishes;
    },
  },
});

const {
  setRecommend,
  setIsDataReady,
  setIsImageCoverLoaded,
  replaceDishInRecommend,
} = recommendlSlice.actions;

const reducer = recommendlSlice.reducer;

/**
 *
 * @returns 初始化主厨推荐数据
 */
const initRecommendlData = () => {
  return async (dispatch: AppDispatch) => {
    const url = "/menu-factory/getChefRecommendDishes";
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;
      // 成功
      if (apiResponse.code === 200) {
        const recommendDishes: IDish[] = apiResponse.data;
        dispatch(setRecommend(recommendDishes));
        dispatch(setIsDataReady(true));
      }
      // 失败
      else if (apiResponse.code === 500) {
        // message.error(apiResponse.msg);
      }
    });
  };
};

/**
 *
 * @param recommend 更新封面图片
 */
const updateImageCove = (recommend: IDish[]) => {
  return async (dispatch: AppDispatch) => {
    const tempRecommend = await Promise.all(
      recommend.map(async (dish) => {
        const tempDishImages = [...dish.dishImages];
        const imageURL = await loadDishImage(tempDishImages[0].uid);
        const updatedDishImages = [...tempDishImages].map((imageObj, i) => {
          if (i === 0) {
            return { ...imageObj, url: imageURL };
          } else {
            return imageObj;
          }
        });
        return { ...dish, imageCover: imageURL, dishImages: updatedDishImages };
      })
    );
    dispatch(setRecommend(tempRecommend));
    dispatch(setIsImageCoverLoaded(true));
  };
};

export { initRecommendlData, updateImageCove, replaceDishInRecommend };
export default reducer;
