import { useEffect, useState } from "react";
import { IDish } from "../../definition/customTypes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { updateImageCove } from "../../store/modules/dish/dishSlice";
import { Badge, ErrorBlock, Image, Skeleton, Tag } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { Constant } from "../../definition/config";
import {
  setDishIdInDetailPage,
  setPage,
} from "../../store/modules/windowSlice";
import CartButton from "../../component/cartButton";

interface IProps {
  categoryId: string;
}
function DishesList(props: IProps) {
  const { categoryId } = props;

  // 该分类下的所有菜品
  const [dishesInCategory, setDishesInCategory] = useState<IDish[]>([]);

  // 菜品
  const { dishes } = useSelector((state: RootState) => state.dishes);

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 国际化
  const { t } = useTranslation();

  /**
   * 筛选出该菜品分类下的菜品
   */
  useEffect(() => {
    if (dishes.length > 0) {
      const dishesInCategory = dishes.filter(
        (dish) => dish.categoryId === categoryId
      );
      setDishesInCategory(dishesInCategory);
    }
  }, [categoryId, dishes]);

  /**
   * 更新图片
   */
  useEffect(() => {
    if (dishesInCategory.length > 0) {
      const dishesNoCover = dishesInCategory.filter((dish) => !dish.imageCover);
      if (dishesNoCover.length === 0) {
        return;
      }
      dispatch(updateImageCove(dishesNoCover));
    }
  }, [dishesInCategory]);

  return (
    <div className="flex flex-col gap-2 mx-2">
      {dishesInCategory.length > 0 ? (
        dishesInCategory.map((dish, index) => {
          return (
            <div key={index} className="flex flex-row gap-2 w-full">
              {dish.dishStatus === "SOLD_OUT" ? (
                <Badge
                  content={t("app.detail.stock.soldOut")}
                  style={{ "--right": "38%", "--top": "9%" }}
                >
                  <Image
                    src={dish.imageCover}
                    className="size-20 rounded-md"
                    placeholder={
                      <Skeleton
                        animated
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: "6px",
                        }}
                      />
                    }
                    fallback={Constant.IMAGE_FALLBACK}
                  />
                </Badge>
              ) : (
                <Image
                  src={dish.imageCover}
                  className="size-20 rounded-md"
                  placeholder={
                    <Skeleton
                      animated
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: "6px",
                      }}
                    />
                  }
                  fallback={Constant.IMAGE_FALLBACK}
                  onClick={() => {
                    dispatch(setDishIdInDetailPage(dish.dishId));
                    dispatch(setPage("detail"));
                  }}
                />
              )}

              <div className="truncate text-base w-40 grow">
                <div
                  onClick={() => {
                    dispatch(setDishIdInDetailPage(dish.dishId));
                    dispatch(setPage("detail"));
                  }}
                  className="overflow-auto"
                >
                  {dish.dishName}
                </div>
                <div>
                  <Tag color="#E5E7EB" className="text-gray-600">
                    {dish.dishWeight}
                  </Tag>
                </div>

                <div className="mt-3 flex flex-row gap-2">
                  <div className="text-[#FE4A34] text-lg">
                    <span className="text-xs">$</span>
                    {dish.discounting ? dish.preferentialPrice : dish.price}
                  </div>
                  {dish.discounting ? (
                    <div className="text-[#A4A1A1] text-sm flex items-center line-through">
                      <span className="text-xs">$</span>
                      {dish.price}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="w-full h-full flex flex-row justify-end items-end pb-2 text-sm">
                    <CartButton
                      currentDish={dish}
                      isDetail={false}
                      idPrefix="category"
                      chosenSpecifications={[]}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="mr-4"></div> */}
            </div>
          );
        })
      ) : (
        <div className="flex flex-row justify-center">
          <ErrorBlock
            status="empty"
            title={t("app.category.empty.title")}
            description={t("app.category.empty.description")}
            className="flex flex-col items-center"
          />
        </div>
      )}
    </div>
  );
}

export default DishesList;
