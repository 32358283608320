import React, { useEffect, useState } from "react";
import {
  ICartDish,
  ICartSpecification,
  IDish,
  ISpecification,
} from "../definition/customTypes";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Stepper } from "antd-mobile";
import { useTranslation } from "react-i18next";
import {
  deleteDishFromCart,
  saveDishIntoCart,
} from "../store/modules/cart/cartSlice";
import {
  setDishRefId,
  setImgURL,
  setIsAnimating,
} from "../store/modules/itemDropSlice";
import { delay } from "../definition/utils";
import {
  setCurrentDish,
  setShowSpecificationModal,
} from "../store/modules/dish/specificationSlice";
import { setShouldUpdateCategoryBadge } from "../store/modules/category/categorySlice";
interface IProps {
  currentDish: IDish;
  isDetail: boolean;
  idPrefix: string; // 按钮所在的页面来源
  chosenSpecifications: ICartSpecification[]; // 已选择的规格，对于除了 DishSpecificationModal 组件传递数据外，其他组件传递一个空数组[]
}
function CartButton(props: IProps) {
  const { currentDish, isDetail, idPrefix, chosenSpecifications } = props;
  // 国际化
  const { t } = useTranslation();

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();
  // 购物车数据
  const { cartData } = useSelector((state: RootState) => state.cart);

  const { tableId } = useSelector((state: RootState) => state.window);

  // 当前菜品在购物车中的数量
  const [count, setCount] = useState<number>(0);

  // 控制购物车按钮的 loading 状态
  const [loading, setLoading] = useState(false);

  // 最近一次加入到购物车中的该菜品
  const [latestSameDishInCart, setLatestSameDishInCart] =
    useState<ICartDish | null>(null);

  /**
   * 计算当前菜品在购物车中的数量
   */
  useEffect(() => {
    if (currentDish) {
      const dishesInCart = cartData.filter(
        (dishInCart) => dishInCart.dishId === currentDish.dishId
      );

      setLatestSameDishInCart(
        // 购物车中最后一个 为最近一次加入到购物车中的该菜品
        dishesInCart.length > 0 ? dishesInCart[dishesInCart.length - 1] : null
      );

      // 只有添加购物车时，延迟300毫秒，配合坠落动画。移除购物车时，不延迟
      if (count < dishesInCart.length) {
        delay(300).then(() => {
          setCount(dishesInCart.length);
          setLoading(false);
        });
      } else {
        setCount(dishesInCart.length);
        setLoading(false);
      }
    }
  }, [cartData, currentDish]);

  const handleAddtoCart = () => {
    dispatch(setImgURL(currentDish.imageCover));
    dispatch(setDishRefId(idPrefix + "_" + currentDish.dishId));
    dispatch(setIsAnimating(true));
  };

  /**
   * 将一个菜品添加到购物车.
   * @param dish
   */
  const addDishIntoCart = (
    dish: IDish,
    chosenSpecifications: ICartSpecification[]
  ) => {
    setLoading(true);
    dispatch(saveDishIntoCart(tableId, dish, chosenSpecifications));
    handleAddtoCart();
  };

  /**
   * 从购物车中移除一个菜品
   * @param dish
   */
  const removeOneDishFromCart = (dish: ICartDish, index: number) => {
    setLoading(false);
    dispatch(deleteDishFromCart(tableId, dish, index));
  };

  return (
    <>
      {/* 详情页面，菜品没有规格，未添加购物车 */}
      {count === 0 && !currentDish?.dishSpecifications && isDetail ? (
        <div id={idPrefix + "_" + currentDish?.dishId}>
          <Button
            style={{
              lineHeight: "12px",
            }}
            className="h-6 text-xs"
            color="warning"
            loading={loading}
            disabled={currentDish?.dishStatus === "SOLD_OUT"}
            onClick={() => addDishIntoCart(currentDish, [])}
          >
            <div id={idPrefix + "_" + currentDish?.dishId}>
              + {t("app.detail.addCart")}
            </div>
          </Button>
        </div>
      ) : //   非详情页面，菜品没有规格，添加购物车之前
      count === 0 && !currentDish?.dishSpecifications && !isDetail ? (
        <div id={idPrefix + "_" + currentDish.dishId}>
          <Button
            loading={loading}
            shape="rounded"
            size="mini"
            fill="solid"
            color="warning"
            className="size-6 text-sm flex justify-center items-center"
            disabled={currentDish?.dishStatus === "SOLD_OUT"}
            onClick={() => addDishIntoCart(currentDish, [])}
          >
            +
          </Button>
        </div>
      ) : //   菜品有规格，非规格页面，添加购物车之前
      count === 0 &&
        currentDish?.dishSpecifications &&
        idPrefix !== "specification" ? (
        <Button
          style={{
            lineHeight: "10px",
          }}
          className="h-6 text-xs"
          color="warning"
          disabled={currentDish?.dishStatus === "SOLD_OUT"}
          onClick={() => {
            dispatch(setCurrentDish(currentDish));
            dispatch(setShowSpecificationModal(true));
          }}
        >
          {t("app.cart.specification")}
        </Button>
      ) : //   菜品有规格，在规格页面，添加购物车之前
      count === 0 &&
        currentDish?.dishSpecifications &&
        idPrefix === "specification" ? (
        <div id={idPrefix + "_" + currentDish?.dishId}>
          <Button
            style={{
              lineHeight: "12px",
            }}
            className="h-6 text-xs"
            color="warning"
            loading={loading}
            disabled={(function () {
              const requiredSpecifications =
                currentDish.dishSpecifications.filter((s) => s.required);
              return !(
                requiredSpecifications.length === chosenSpecifications.length ||
                requiredSpecifications.length === 0
              );
            })()}
            onClick={() => addDishIntoCart(currentDish, chosenSpecifications)}
          >
            <div id={idPrefix + "_" + currentDish?.dishId}>
              + {t("app.detail.addCart")}
            </div>
          </Button>
        </div>
      ) : //   菜品有规格，在规格页面，添加购物车后
      count > 0 &&
        currentDish?.dishSpecifications &&
        idPrefix === "specification" ? (
        <div className="flex flex-row gap-1">
          <div id={idPrefix + "_" + currentDish.dishId}>
            <Button
              loading={loading}
              shape="rounded"
              size="mini"
              fill="solid"
              className="size-6 text-sm flex justify-center items-center"
              style={{
                borderColor: "var(--adm-color-warning)",
              }}
              onClick={() =>
                removeOneDishFromCart(
                  latestSameDishInCart as ICartDish,
                  latestSameDishInCart?.index as number
                )
              }
            >
              -
            </Button>
          </div>
          <div className="flex h-6 items-end px-2">{count}</div>
          <Button
            loading={loading}
            shape="rounded"
            size="mini"
            fill="solid"
            color="warning"
            className="size-6 text-sm flex justify-center items-center"
            onClick={() => addDishIntoCart(currentDish, chosenSpecifications)}
          >
            +
          </Button>
        </div>
      ) : // 菜有规格，在详情页，添加购物车后
      count > 0 &&
        currentDish.dishSpecifications &&
        // isDetail &&
        idPrefix !== "specification" ? (
        <Badge
          content={count}
          style={{ "--right": "18%", "--top": "8%" }}
          className="p-0.5"
        >
          <Button
            style={{
              lineHeight: "10px",
            }}
            className="h-6 text-xs"
            color="warning"
            disabled={currentDish?.dishStatus === "SOLD_OUT"}
            onClick={() => {
              dispatch(setCurrentDish(currentDish));
              dispatch(setShowSpecificationModal(true));
            }}
          >
            {t("app.cart.specification")}
          </Button>
        </Badge>
      ) : count > 0 && !currentDish?.dishSpecifications ? (
        <div className="flex flex-row gap-1">
          <div id={idPrefix + "_" + currentDish.dishId}>
            <Button
              loading={loading}
              shape="rounded"
              size="mini"
              fill="solid"
              className="size-6 text-sm flex justify-center items-center"
              style={{
                borderColor: "var(--adm-color-warning)",
              }}
              onClick={() =>
                removeOneDishFromCart(
                  latestSameDishInCart as ICartDish,
                  latestSameDishInCart?.index as number
                )
              }
            >
              -
            </Button>
          </div>
          <div className="flex h-6 items-end px-2">{count}</div>
          <Button
            loading={loading}
            shape="rounded"
            size="mini"
            fill="solid"
            color="warning"
            className="size-6 text-sm flex justify-center items-center"
            onClick={() => addDishIntoCart(currentDish, [])}
          >
            +
          </Button>
        </div>
      ) : currentDish?.dishSpecifications ? (
        <></>
      ) : (
        <></>
      )}
    </>
  );
}

export default CartButton;
