import React, { useEffect } from "react";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

import { Button, ErrorBlock, Image, Skeleton, Tag } from "antd-mobile";
import { useTranslation } from "react-i18next";
import {
  initBestOffersData,
  updateImageCove,
} from "../../../store/modules/bestOffers/bestOffersSlice";
import { setPage } from "../../../store/modules/windowSlice";
import CartButton from "../../../component/cartButton";
import { BestOfferIcon, FoodIcon } from "../../../definition/customIcons";

function BestOffers() {
  // 国际化
  const { t } = useTranslation();
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 保存到 Redux 中的 merchantId, index.tsx 中初始化
  const { merchantId } = useSelector((state: RootState) => state.lang);

  const { bestOffers, isDataReady, isImageCoverLoaded } = useSelector(
    (state: RootState) => state.bestOffers
  );

  const { token } = useSelector((state: RootState) => state.window);

  const topDishesPlaceholder = [1, 2, 3, 4, 5, 6];

  /**
   * 加载 月度榜单菜品数据
   */
  useEffect(() => {
    if (bestOffers.length === 0 && merchantId !== "" && token !== "") {
      dispatch(initBestOffersData());
    }
  }, [bestOffers, merchantId, token, dispatch]);

  /**
   * 加载 月度排行图片
   */
  useEffect(() => {
    if (isDataReady && !isImageCoverLoaded) {
      //   console.log(topDishes);
      dispatch(updateImageCove(bestOffers));
    }
  }, [isDataReady, isImageCoverLoaded, dispatch]);

  return (
    <div className="flex flex-col gap-2 mt-2 mx-2">
      {isDataReady && bestOffers.length === 0 ? (
        <div className="mt-14">
          <ErrorBlock
            image={<BestOfferIcon />}
            style={{
              "--image-height": "160px",
            }}
            title={t("app.cart.empty.title")}
            description={<span>{t("app.cart.empty.description")}</span>}
            className="flex flex-col justify-center items-center"
          ></ErrorBlock>
        </div>
      ) : isDataReady && bestOffers.length > 0 ? (
        bestOffers.map((dish, index) => {
          return (
            <div key={index} className="flex flex-row justify-between">
              <div className="flex flex-row gap-2 w-full">
                <Image
                  src={dish.imageCover}
                  fit="cover"
                  className="size-32 rounded-lg"
                  onClick={() => dispatch(setPage("detail"))}
                />
                <div className="flex flex-col grow justify-between">
                  <div className="">
                    <div
                      className="text-base"
                      onClick={() => dispatch(setPage("detail"))}
                    >
                      {dish.dishName}
                    </div>
                    <div className="flex flex-row mt-2">
                      <div>
                        <Tag color="#E5E7EB" className="text-gray-600">
                          {dish.dishWeight}
                        </Tag>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Tag color="danger" fill="outline">
                        {t("app.home.save")} $
                        {(dish.price - dish.preferentialPrice).toFixed(1)}
                      </Tag>
                    </div>
                    <div className="mt-2 flex flex-row justify-between gap-2">
                      <div className="flex flex-row gap-2">
                        <div className="text-[#FE4A34] text-lg ">
                          <span className="text-xs">$</span>
                          {dish.discounting
                            ? dish.preferentialPrice
                            : dish.price}
                        </div>
                        {dish.discounting ? (
                          <div className="text-[#A4A1A1] text-sm flex items-center line-through">
                            <span className="text-xs">$</span>
                            {dish.price}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="flex items-end">
                        <CartButton
                          currentDish={dish}
                          isDetail={false}
                          idPrefix="bestOffers"
                          chosenSpecifications={[]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        topDishesPlaceholder.map((_, index) => (
          <div key={index} className="flex flex-row gap-2">
            <div>
              {/* 骨架图片 */}
              <Skeleton
                animated
                style={{
                  borderRadius: "8px",
                }}
                className="size-32"
              />
            </div>
            <div className="basis-1/2 ">
              <Skeleton.Title
                animated
                style={{
                  marginTop: "3px",
                  height: "26px",
                  borderRadius: "4px",
                }}
              />
              <Skeleton.Paragraph
                lineCount={2}
                animated
                style={{
                  marginTop: "3px",
                  height: "16px",
                  borderRadius: "4px",
                }}
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default BestOffers;
