import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // 直接初始化，不使用后端加载
  .init({
    fallbackLng: "en", // 默认语言
    debug: true, // 调试模式，开发环境下建议开启
    interpolation: {
      escapeValue: false, // React 已经有 XSS 保护，不需要转义
    },
    resources: {
      en: {
        translation: {
          app: {
            bottom: {
              home: "Home",
              category: "Category",
              cart: "Cart",
              orders: "Orders",
            },
            langResult: "You Have Chosen",
            invalidTable:
              "Invalid table, please scan the QR code and try again!",
            search: {
              placeholder: "Search for tasty food",
            },
            mSale: "Monthly sales",
            rank: "Store sales rank: ",
            home: {
              recommend: "Chef's Special",
              topDishes: "Top Dishes",
              offer: "Best Offers",
              save: "Save",
              endPage: "That's all~",
            },
            category: {
              empty: {
                title: "Oops, no dishes here!",
                description: "Let's explore some other dishes.",
              },
            },
            detail: {
              addCart: "Add to Cart",
              stock: {
                inStock: "In Stock",
                soldOut: "Sold Out",
              },
              details: "Details",
              desc: "Description",
              ingredients: "Ingredients",
              dishWeight: "Portion",
              otherDishes: "Other Similar Dishes",
              nopack: "Sorry, this dish cannot be packed for takeout.",
            },
            cart: {
              specification: "Options",
              chosenSpecification: "Selected Options",
              empty: {
                title: "Nothing here, go ahead and order!",
                description: "Tasty dishes are waiting for you.",
              },
              orderSuccess: {
                title: "Order placed successfully",
                desc1:
                  "We operate on a pay-before-you-eat basis. Please make your payment at the counter!",
                desc2:
                  "Please proceed to the counter to complete your payment.",
              },

              pack: "Would you like to take away?",
              note: "Note",
              message: "Message for the chef",
              placeOrder: "Order Now",
              noPackTips: {
                content1:
                  "Your cart contains items that cannot be packed for takeout, while other items can be packed normally. Are you sure you want to continue?",
                content2: "Cannot be packed for takeout",
              },
              dialog: {
                confirmText: "Confirm",
                cancelText: "Cancel",
              },
            },
            originalPrice: "Regular Price",
            discount: "Discount",
            deduct: "Saved",
            total: "Total",
            required: "Required",
            order: {
              paid: "Payment Complete",
              unPaid: "Payment Pending",
              tipsOnButton: "Go to Order~",
              cookingStatus: {
                waiting: "Waiting to Cook",
                cooking: "Cooking in Progress",
                dishesServed: "All Dishes Served",
              },
              closeText: "Order has been closed",
              packingCode: "Pickup Number",
              paymentCountdown: "Please pay within {{timeLeft}} min.",
              orderId: "Order ID",
              orderDate: "Order Date",
              paymentStatus: "Payment Status",
            },
          },
        },
      },
      de: {
        translation: {
          app: {
            bottom: {
              home: "Startseite",
              category: "Kategorie",
              cart: "Warenkorb",
              orders: "Bestellungen",
            },
            langResult: "Sie haben gewählt",
            invalidTable:
              "Ungültige Tabelle, bitte scannen Sie den Code und versuchen Sie es erneut!",
            search: {
              placeholder: "Leckeres Essen finden",
            },
            mSale: "Monatsverkäufe",
            rank: "Verkaufsrang des Geschäfts: ",
            home: {
              recommend: "Chef’s Empfehlung",
              topDishes: "Beliebteste Speisen",
              offer: "Tolle Angebote",
              save: "Spare Sofort",
              endPage: "Ende der Seite~",
            },
            category: {
              empty: {
                title: "Hier gibt es keine Gerichte mehr!",
                description: "Schau dir doch etwas anderes an.",
              },
            },
            detail: {
              addCart: "In den Warenkorb",
              stock: {
                inStock: "Auf Lager",
                soldOut: "Ausverkauft",
              },
              details: "Details",
              desc: "Beschreibung",
              ingredients: "Zutaten",
              dishWeight: "Portion",
              otherDishes: "Weitere ähnliche Gerichte",
              nopack: "Dieses Gericht ist nicht für Takeaway verfügbar.",
            },
            cart: {
              specification: "Optionen",
              chosenSpecification: "Ausgewählte Optionen",
              empty: {
                title: "Nichts hier, bestell doch was!",
                description: "Leckere Gerichte warten auf dich.",
              },
              orderSuccess: {
                title: "Bestellung erfolgreich gesendet",
                desc1:
                  "Bitte zuerst bezahlen, dann essen. Zahlen Sie rechtzeitig an der Kasse!",
                desc2:
                  "Bitte gehen Sie zur Kasse, um die Zahlung abzuschließen.",
              },
              pack: "Wollen Sie es zum Mitnehmen?",
              note: "Bemerkung",
              message: "Nachricht an den Koch",
              placeOrder: "Jetzt Bestellen",
              noPackTips: {
                content1:
                  "In Ihrem Warenkorb befinden sich Artikel, die nicht zum Mitnehmen verpackt werden können, während andere Artikel normal verpackt werden können. Sind Sie sicher, dass Sie die Artikel zum Mitnehmen nehmen möchten?",
                content2: "Nicht für Takeaway verfügbar",
              },
              dialog: {
                confirmText: "Bestätigen",
                cancelText: "Abbrechen",
              },
            },
            originalPrice: "Regulärer Preis",
            discount: "Rabatt",
            deduct: "Gespart",
            total: "Summe",
            required: "Pflichtwahl",
            order: {
              paid: "Bezahlt",
              unPaid: "Nicht bezahlt",
              tipsOnButton: "Zum Bestellen gehen~",
              cookingStatus: {
                waiting: "Warten auf Zubereitung",
                cooking: "In Zubereitung",
                dishesServed: "Alle Gerichte serviert",
              },
              closeText: "Bestellung wurde geschlossen",
              packingCode: "Abholnummer",
              paymentCountdown: "Bitte in {{timeLeft}} Min. bezahlen.",
              orderId: "Bestellnummer",
              orderDate: "Bestelldatum",
              paymentStatus: "Zahlungsstatus",
            },
          },
        },
      },
      zh: {
        translation: {
          app: {
            bottom: {
              home: "首页",
              category: "分类",
              cart: "购物车",
              orders: "订单",
            },
            langResult: "你选择了",
            invalidTable: "无效餐桌，请扫码重试!",
            search: {
              placeholder: "搜索美食",
            },
            mSale: "月售",
            rank: "门店销量排名: ",
            home: {
              recommend: "主厨推荐",
              topDishes: "本月热榜菜",
              offer: "超值优惠",
              save: "立省",
              endPage: "到底啦~",
            },
            category: {
              empty: {
                title: "哎呀，这里没有菜肴了!",
                description: "去看看其他的吧.",
              },
            },
            detail: {
              addCart: "加入购物车",
              stock: {
                inStock: "库存 充足",
                soldOut: "售 罄",
              },
              details: "详 情",
              desc: "掌柜描述",
              ingredients: "主料",
              dishWeight: "份量",
              otherDishes: "同类其他菜品",
              nopack: "很抱歉，该菜品不支持打包哦!",
              placeOrder: "立即下单",
            },
            cart: {
              specification: "选规格",
              chosenSpecification: "已选规格",
              empty: {
                title: "空空如也，快去点菜吧～",
                description: "美味菜肴等着你",
              },
              orderSuccess: {
                title: "下单成功",
                desc1: "本店实行先支付后就餐哦，请及时前往柜台支付!",
                desc2: "您可前往柜台完成支付哦~",
              },

              pack: "想要打包带走?",
              note: "留言",
              message: "给厨师留言",
              placeOrder: "立即下单",
              noPackTips: {
                content1:
                  "购物车中存在不可打包的菜品，其他菜品可正常打包。你确定要打包带走吗？",
                content2: "不可打包的菜品标签",
              },
              dialog: {
                confirmText: "确定",
                cancelText: "取消",
              },
            },
            originalPrice: "菜品原价",
            discount: "折扣",
            deduct: "共减",
            total: "总计",
            required: "必选",
            order: {
              paid: "已支付",
              unPaid: "未支付",
              tipsOnButton: "去点菜~",
              cookingStatus: {
                waiting: "待烹饪",
                cooking: "烹饪中",
                dishesServed: "菜已上完",
              },
              closeText: "订单已关闭",
              packingCode: "取餐号",
              paymentCountdown: "请在 {{timeLeft}} 分钟内完成支付",
              orderId: "订单编号",
              orderDate: "下单时间",
              paymentStatus: "支付状态",
            },
          },
        },
      },
    },
  });

export default i18n;
