import { configureStore } from "@reduxjs/toolkit";

import settingSlice from "./modules/setting/settingSlice";
import windowSlice from "./modules/windowSlice";
import langSlice from "./modules/lang/langSlice";
import carouselSlice from "./modules/carousel/carouselSlice";
import recommendSlice from "./modules/recommend/recommendSlice";
import topDishesSlice from "./modules/topDishes/topDishesSlice";
import bestOffersSlice from "./modules/bestOffers/bestOffersSlice";
import categorySlice from "./modules/category/categorySlice";
import dishSlice from "./modules/dish/dishSlice";
import cartSlice from "./modules/cart/cartSlice";
import itemDropSlice from "./modules/itemDropSlice";
import specificationSlice from "./modules/dish/specificationSlice";
import orderSlice from "./modules/order/orderSlice";

const store = configureStore({
  reducer: {
    setting: settingSlice,
    window: windowSlice,
    lang: langSlice,
    carousel: carouselSlice,
    recommend: recommendSlice,
    topDishes: topDishesSlice,
    bestOffers: bestOffersSlice,
    category: categorySlice,
    dishes: dishSlice,
    cart: cartSlice,
    itemDrop: itemDropSlice,
    specification: specificationSlice,
    order: orderSlice,
  },
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

export { AppDispatch, RootState };

export default store;
