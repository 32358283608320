import { useEffect, useState } from "react";
import Main from "./body";
import { Route, Routes } from "react-router-dom";
import { AppDispatch, RootState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { setVwidth } from "./store/modules/windowSlice";
import Detail from "./body/detail/detail";
import DishDropIntoCart from "./component/dishDropIntoCart";
import DishSpecificationModal from "./component/dishSpecificationModal";
import Cart from "./body/cart/cart";

function App() {
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 控制页面切换
  const { page } = useSelector((state: RootState) => state.window);

  /**
   * 更新窗口视图的高度
   */
  useEffect(() => {
    dispatch(setVwidth(window.innerWidth));
    // 在组件加载时添加事件监听器
    window.addEventListener("resize", handleResize);
    // 清除事件监听器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 更新窗口大小时调整高度
  const handleResize = () => {
    dispatch(setVwidth(window.innerWidth));
  };

  return (
    <div className="warm-theme scrollbar-hidden">
      {page === "home" ? (
        <Main />
      ) : page === "detail" ? (
        <Detail />
      ) : page === "cart" ? (
        <Cart />
      ) : (
        <></>
      )}
      <DishDropIntoCart />
      <DishSpecificationModal />
    </div>
  );
}

export default App;
