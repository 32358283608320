import { Image, Skeleton, Swiper } from "antd-mobile";
import { memo, useEffect } from "react";
import { IDish } from "../../../definition/customTypes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  initCarouselData,
  updateImageCove,
} from "../../../store/modules/carousel/carouselSlice";
import {
  setDishIdInDetailPage,
  setPage,
} from "../../../store/modules/windowSlice";

const Carousel = memo(function () {
  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  const { carousel, isDataReady, isImageCoverLoaded } = useSelector(
    (state: RootState) => state.carousel
  );

  // 保存到 Redux 中的 merchantId, index.tsx 中初始化
  const { merchantId } = useSelector((state: RootState) => state.lang);

  const { token } = useSelector((state: RootState) => state.window);

  /**
   * 加载 首页轮播
   */
  useEffect(() => {
    if (carousel.length === 0 && merchantId !== "" && token !== "") {
      dispatch(initCarouselData());
    }
  }, [carousel, merchantId, token, dispatch]);

  /**
   * 加载轮播图片
   */
  useEffect(() => {
    if (isDataReady && !isImageCoverLoaded) {
      dispatch(updateImageCove(carousel));
    }
  }, [isDataReady, isImageCoverLoaded, dispatch]);

  return (
    <Swiper
      loop
      indicator={(total, current) => (
        <div className={"customIndicator"}>{`${current + 1} / ${total}`}</div>
      )}
      className="w-full h-full rounded-lg drop-shadow"
    >
      {carousel.length > 0 ? (
        carousel.map((dish: IDish, index) => {
          return (
            <Swiper.Item key={index}>
              <Image
                src={dish?.imageCover}
                fit="cover"
                className="w-full h-full"
                onClick={() => {
                  dispatch(setDishIdInDetailPage(dish.dishId));
                  dispatch(setPage("detail"));
                }}
              />
            </Swiper.Item>
          );
        })
      ) : (
        <Swiper.Item>
          <Skeleton
            animated
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          />
        </Swiper.Item>
      )}
      {}
    </Swiper>
  );
});

export default Carousel;
