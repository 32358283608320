import { createSlice } from "@reduxjs/toolkit";

/**
 * 语言选项，偏好语言
 */
const langSlice = createSlice({
  name: "lang",
  initialState: {
    merchantId: "",
    availableLanguages: ["en"],
    chosenLanguage: "",
  },
  reducers: {
    /**
     * 设置 MerchantId 时，检查缓存中是否存在 语言偏好，若存在则设置上
     * @param state
     * @param action
     */
    setMerchantId(state, action) {
      const merchantIdFromQRCode = action.payload;
      state.merchantId = merchantIdFromQRCode;
      const langKey = "major_language_" + merchantIdFromQRCode;
      const majorLang = localStorage.getItem(langKey);
      if (majorLang) {
        state.chosenLanguage = majorLang;
      }
    },
    setAvailableLanguages(state, action) {
      state.availableLanguages = action.payload;
    },
    setChosenLanguage(state, action) {
      state.chosenLanguage = action.payload;
    },
    /**
     * 切换语言时，同时覆盖本地缓存
     * @param state
     * @param action
     */
    switchLanguage(state, action) {
      const langKey = "major_language_" + state.merchantId;
      const toLanguage = action.payload;
      localStorage.setItem(langKey, toLanguage);
      state.chosenLanguage = toLanguage;
    },
  },
});

const {
  setMerchantId,
  setAvailableLanguages,
  setChosenLanguage,
  switchLanguage,
} = langSlice.actions;

const reducer = langSlice.reducer;

export {
  setMerchantId,
  setAvailableLanguages,
  setChosenLanguage,
  switchLanguage,
};
export default reducer;
