import { Button, Divider, Popup } from "antd-mobile";
import {
  ApiResponse,
  IPaymentAndCookingStatus,
  Order,
} from "../../definition/customTypes";
import { current } from "@reduxjs/toolkit";
import { Image, Popover, Tag } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { CloseOutline } from "antd-mobile-icons";
import AutoCancelOrderCountdown from "../../component/autoCancelOrderCountdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect, useRef } from "react";
import axiosInstance from "../../definition/request";
import { setCurrentOrders } from "../../store/modules/order/orderSlice";

interface IProps {
  currentOrder: Order;
  setCurrentOrder: (currentOrder: Order) => void;
  orderDetailVisible: boolean;
  setOrderDetailVisible: (orderDetailVisible: boolean) => void;
}
/**
 * 订单详情页的 Popup
 * @param props
 * @returns
 */
function OrderDetail(props: IProps) {
  const {
    currentOrder,
    setCurrentOrder,
    orderDetailVisible,
    setOrderDetailVisible,
  } = props;

  // 国际化
  const { t } = useTranslation();

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 设置数据
  const { setting } = useSelector((state: RootState) => state.setting);

  // 当前订单数据
  const { currentOrders } = useSelector((state: RootState) => state.order);

  const tagRef = useRef<HTMLDivElement | null>(null); // 用于绑定 Popover

  /**
   * 获取订单是否支付 以及 烹饪状态
   */
  useEffect(() => {
    if (orderDetailVisible) {
      const url =
        "/dish-order/getOrderPaymentAndCookingStatus/" + currentOrder.id;
      axiosInstance.get<ApiResponse>(url).then((response) => {
        const apiResponse: ApiResponse = response.data;
        if (apiResponse.code === 200) {
          const paymentAndCookingStatus: IPaymentAndCookingStatus =
            apiResponse.data;
          //   console.log(paymentAndCookingStatus);
          // 说明订单已被取消，redis 缓存中不存在
          if (!paymentAndCookingStatus) return;

          let tempCurrentOrder = null;
          // 更新订单中的状态
          const tempCurrentOrders = currentOrders.map((order) => {
            if (order.id === currentOrder.id) {
              //   if (paymentAndCookingStatus.paid) {
              tempCurrentOrder = {
                ...currentOrder,
                paid: paymentAndCookingStatus.paid,
                cookingStatus: paymentAndCookingStatus.cookingStatus,
              };
              //   } else {
              //     tempCurrentOrder = {
              //       ...currentOrder,
              //       paid: false,
              //     };
              //   }
              return tempCurrentOrder;
            }
            return order;
          });
          dispatch(setCurrentOrders(tempCurrentOrders));
          setCurrentOrder(tempCurrentOrder as unknown as Order);
        }
      });
    }
  }, [orderDetailVisible]);

  return (
    <Popup
      visible={orderDetailVisible}
      onMaskClick={() => {
        setOrderDetailVisible(false);
      }}
      bodyStyle={{
        height: "80vh",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        backgroundColor: "#F7F7F7",
      }}
      //   showCloseButton
      closeOnMaskClick
    >
      <div
        className="mt-[-30px] mb-[30px] flex justify-end mx-3"
        onClick={() => {
          setOrderDetailVisible(false);
        }}
      >
        <CloseOutline fontSize={20} className="text-gray-200" />
      </div>
      <div className="h-full flex flex-col gap-2 justify-start mx-2">
        {/* 菜列表部分 */}
        <div className="flex flex-col gap-2 mt-[-8px]">
          <div className="text-xl font-semibold">
            {/* 如果订单未支付，且先支付后就餐， 则显示倒计时组件 */}
            {currentOrder?.paid === false && setting.diningSetting.payFirst ? (
              <AutoCancelOrderCountdown order={currentOrder} />
            ) : (
              /* 否则 显示烹饪状态 */
              <div>
                {currentOrder?.cookingStatus === "WAITING"
                  ? t("app.order.cookingStatus.waiting")
                  : currentOrder?.cookingStatus === "COOKING"
                  ? t("app.order.cookingStatus.cooking")
                  : t("app.order.cookingStatus.dishesServed")}
              </div>
            )}
            {currentOrder?.packing ? (
              <div className="flex items-center">
                <Tag color="warning" fill="outline">
                  Pack
                </Tag>
                <span className="font-semibold pl-2">
                  {" "}
                  {currentOrder.packingCode}
                </span>{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="max-h-80 flex flex-col gap-2 bg-white drop-shadow-sm px-2 pt-2 pb-5 rounded-lg overflow-auto scrollbar-hidden">
            {currentOrder?.dishes.map((dish, index) => {
              return (
                <div key={index} className="flex flex-row gap-2">
                  <div className="flex items-start">
                    <Image
                      src={dish.imageCover}
                      className="size-20 rounded-md ml-2"
                    />
                  </div>
                  <div className="flex flex-col justify-between gap-2 grow mr-2">
                    <div className="flex flex-row justify-between text-sm">
                      <div>{dish.dishName}</div>
                    </div>

                    <div className="flex flex-row gap-3">
                      <Tag color="#E5E7EB" className="text-gray-600">
                        {dish.dishWeight}
                      </Tag>
                      {dish.allowPacking ? (
                        <></>
                      ) : (
                        <div ref={tagRef}>
                          <Popover
                            content={t("app.detail.nopack")}
                            trigger="click"
                            placement="bottom"
                            defaultVisible
                            getContainer={() => tagRef.current || document.body} // 避免 findDOMNode
                          >
                            <Tag
                              color="warning"
                              fill="outline"
                              className="line-through"
                            >
                              Pack
                            </Tag>
                          </Popover>
                        </div>
                      )}
                    </div>
                    {dish.chosenSpecifications.length > 0 ? (
                      <div className="text-xs text-gray-400">
                        {dish.chosenSpecifications.map((cs, index) =>
                          index === 0 ? (
                            <span key={index}>
                              {cs.chosenItem.itemName}{" "}
                              {cs.chosenItem.itemPrice > 0 ? (
                                <span className="text-gray-300">|</span>
                              ) : (
                                ""
                              )}
                              <span className="text-[#FE4A34]">
                                {cs.chosenItem.itemPrice === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="text-xs"> +$</span>
                                    {""}
                                    <span>{cs.chosenItem.itemPrice}</span>
                                  </>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span key={index}>
                              , {cs.chosenItem.itemName}{" "}
                              {cs.chosenItem.itemPrice > 0 ? (
                                <span className="text-gray-300">|</span>
                              ) : (
                                ""
                              )}
                              <span className="text-[#FE4A34]">
                                {cs.chosenItem.itemPrice === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="text-xs"> +$</span>
                                    {""}
                                    <span>
                                      {cs.chosenItem.itemPrice.toFixed(2)}
                                    </span>
                                  </>
                                )}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="flex flex-col items-end justify-between mr-2">
                    <div>
                      <div className="text-[#FE4A34] text-lg">
                        <span className="text-xs">$</span>
                        {dish.discounting ? dish.preferentialPrice : dish.price}
                      </div>
                      {dish.discounting ? (
                        <div className="text-[#A4A1A1] text-sm flex items-center line-through justify-end">
                          <span className="text-xs">$</span>
                          {parseFloat(dish.price).toFixed(2)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col gap-2 bg-white p-2 rounded-lg drop-shadow-sm">
            <div className="flex flex-row justify-between">
              <div>{t("app.order.orderId")}:</div>
              <div className="text-[#A4A1A1]">{currentOrder?.id}</div>
            </div>
            <div className="flex flex-row justify-between">
              <div>{t("app.order.orderDate")}:</div>
              <div className="text-[#A4A1A1]">{currentOrder?.orderDate}</div>
            </div>
            <div className="flex flex-row justify-between">
              <div>{t("app.order.paymentStatus")}:</div>
              <div className="text-[#A4A1A1]">
                {currentOrder?.paid ? (
                  <Tag color="success" fill="outline" className="p-1">
                    {t("app.order.paid")}
                  </Tag>
                ) : (
                  <Tag color="danger" fill="outline" className="p-1">
                    {t("app.order.unPaid")}
                  </Tag>
                )}
              </div>
            </div>
            {currentOrder?.note ? (
              <div className="flex flex-row justify-between">
                <div>{t("app.cart.note")}:</div>
                <div className="text-[#A4A1A1]">{currentOrder?.note}</div>
              </div>
            ) : (
              <></>
            )}
            {currentOrder?.packing ? (
              <div className="flex flex-row justify-between">
                <div>{t("app.order.packingCode")}:</div>
                <div className="text-[#A4A1A1]">
                  {currentOrder?.packingCode} 
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* 底部 */}
        <div className="w-full flex flex-col gap-1 mt-8">
          {/* 详情 */}
          <div className="bg-white rounded-lg drop-shadow-sm gap-2 flex flex-col p-4 text-sm">
            <div className="flex flex-row justify-between">
              <div>{t("app.originalPrice")}:</div>
              <div className="text-[#A4A1A1] line-through">
                <span className="text-xs">$</span>
                {(
                  currentOrder?.finalPrice + currentOrder?.deductedPrice
                ).toFixed(2)}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div>{t("app.discount")}:</div>
              <div style={{ color: "var(--adm-color-warning)" }}>
                <span className="text-xs">$</span>
                {currentOrder?.deductedPrice.toFixed(2)}
              </div>
            </div>
            <Divider className="my-1" />
            <div className="flex flex-row justify-between">
              <div>{t("app.total")}:</div>
              <div className="font-medium">
                <span className="text-xs">$</span>
                {currentOrder?.finalPrice.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default OrderDetail;
