import axios from 'axios';

import { ApiCon, Constant } from './config';

const axiosInstance = axios.create({
  baseURL: ApiCon.BASE_RUL,
  timeout: ApiCon.TIMEOUT,
});

/**
 * 对所有的 request 请求进行拦截.
 * 1. 放行不需要token验证的请求;
 * 2. 其他请求，在请求头上携带从缓存中取出来的 token 以及 当前的 merchantId.
 */
axiosInstance.interceptors.request.use(
  (config) => {
    // 确保 headers 不为 undefined
    config.headers = config.headers || {};

    Constant.BYPASS_URL_SUFFIXES.forEach((url_suffix) => {
      if (config.url?.endsWith(url_suffix)) {
        return config;
      }
    });

    const cacheToken = localStorage.getItem('token');
    const merchantId = localStorage.getItem('merchantId');
    if (cacheToken !== null && merchantId !== null) {
      const decodedToken = atob(cacheToken); // base64 decode token after getting from local storage.
      const decodedMerchantId = atob(merchantId);
      config.headers.Authorization = 'Bearer ' + decodedToken;
      config.headers.MerchantId = decodedMerchantId;
    }

    return config;
  },
  (error) => {
    return error;
  }
);

/**
 * 对请求响应进行拦截，针对不同的错误码进行不同的处理.
 */
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 请求发送，收到了明确的错误响应
    if (error.response) {
      const { status, data } = error.response;
      // 处理未授权，非法token, 清除token缓存，刷新页面自动跳转到登录页面
      if (status === 401) {
        console.error(
          'Unauthorized access, possiblely because of the invalid token! '
        );
        return error; // 这里一定要 return error, 否则在下游请求发起处接收不到 response
      } else if (status >= 500) {
        console.error('Server error:', data.message || 'Unknown server error');
      }
    }
    // 请求已发送，但没有收到响应
    else if (error.request) {
      console.error('No response received:', error.request);
    }
    // 其他错误
    else {
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
