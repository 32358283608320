import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../index";
import axiosInstance from "../../../definition/request";
import { ApiResponse, ICartDish, Order } from "../../../definition/customTypes";

import { Constant } from "../../../definition/config";

/**
 * Slice 中的数据结构规范
 */
interface IOrderSlice {
  currentOrders: Order[]; // 当前抽地中的订单数据，因为一张餐桌可能存在多个订单的情况，所以这里是个数组，只是大部分情况这个数据中只有一个元素
}

/**
 * Slice 中数据的初始状态
 */
const initialState: IOrderSlice = {
  currentOrders: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    /** 设置订单数据 */
    setCurrentOrders(state, action) {
      state.currentOrders = action.payload;
    },

    /** 移除一个订单 */
    removeAnOrder(state, action) {
      const { orderId } = action.payload;
      const tempCurrentOrders = state.currentOrders.filter(
        (order) => order.id !== orderId
      );
      state.currentOrders = tempCurrentOrders;
    },
  },
});

const { setCurrentOrders, removeAnOrder } = orderSlice.actions;
const reducer = orderSlice.reducer;

/**
 * 加载指定餐桌 所有未完成的订单
 */
const loadAllUncompletedOrders = (tableId: string) => {
  return async (dispatch: AppDispatch) => {
    const url = "/dish-order/getUncompletedOrdersByTableId/" + tableId;
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;
      if (apiResponse.code === 200) {
        if (apiResponse.data.length === 0) {
          return;
        }
        dispatch(setCurrentOrders(apiResponse.data));
      }
    });
  };
};

export { setCurrentOrders, removeAnOrder, loadAllUncompletedOrders };
export default reducer;
