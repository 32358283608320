import { RefObject, useEffect, useRef, useState } from "react";
import { Badge, Dialog, TabBar, Toast } from "antd-mobile";
import SHA256 from "crypto-js/sha256";
import { useSearchParams } from "react-router-dom";
import {
  AppstoreOutline,
  FaceRecognitionOutline,
  FileOutline,
} from "antd-mobile-icons";

import axiosInstance from "../definition/request";
import { Constant } from "../definition/config";
import { ApiResponse } from "../definition/customTypes";
import { useTranslation } from "react-i18next";
import Category from "./category/category";
import Home from "./home/home";
import Cart from "./cart/cart";
import Orders from "./orders/orders";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { loadMerchantSetting } from "../store/modules/setting/settingSlice";
import { CartSmSVG } from "../definition/customIcons";
import {
  setAvailableLanguages,
  setChosenLanguage,
  setMerchantId,
} from "../store/modules/lang/langSlice";
import {
  setHomeActivePannel,
  setPacking,
  setPage,
  setTableId,
  setTableNumber,
  setToken,
} from "../store/modules/windowSlice";
import { loadAllDishCartData } from "../store/modules/cart/cartSlice";
import { setCartRefId } from "../store/modules/itemDropSlice";
import { delay } from "../definition/utils";

function Main() {
  const [searchParams] = useSearchParams();
  const merchantIdFromQRCode = searchParams.get("merchantId");
  const tableIdFromQRCode = searchParams.get("tableId");
  const packingFromQRCode = searchParams.get("packing");

  // 国际化
  const { t, i18n } = useTranslation();

  // 购物车导航栏引用
  const cartRef = useRef<HTMLDivElement>(null);

  // Redux 初始化
  const dispatch: AppDispatch = useDispatch();

  // 设置数据
  const { setting, isInitialized } = useSelector(
    (state: RootState) => state.setting
  );

  // 用户选择好的 偏好语言, 语言 选项
  const { merchantId, chosenLanguage, availableLanguages } = useSelector(
    (state: RootState) => state.lang
  );

  const { token, packing, tableId, tableNumber, homeActivePannel } =
    useSelector((state: RootState) => state.window);

  // 购物车数据
  const { cartData } = useSelector((state: RootState) => state.cart);

  // 购物车中的物品数量
  const [count, setCount] = useState(0);

  /**
   * 1. 将 QR Code 中的 merchantId 放入到 Redux
   * 2. 如果是 打包带走的专用二维码，将 packing 放入 Redux
   */
  useEffect(() => {
    if (merchantId === "") {
      const base64MerchantId = btoa(merchantIdFromQRCode as string);
      localStorage.setItem("merchantId", base64MerchantId);
      dispatch(setMerchantId(merchantIdFromQRCode));

      if (packingFromQRCode === "1") {
        dispatch(setPacking(true));
      } else {
        dispatch(setPacking(false));
        dispatch(setTableId(tableIdFromQRCode));
      }
    }
  }, [merchantId]);

  /**
   * 2. 登录请求 Token
   */
  useEffect(() => {
    if (token === "" && merchantId !== "") {
      const { name, password, accountCode } = Constant.ACCOUNT;
      const hashedPassword = SHA256(password).toString();
      const url = "/oidc-service/auth/login";
      axiosInstance
        .post(url, { name, password: hashedPassword, accountCode })
        .then((response) => {
          const apiResponse: ApiResponse = response.data;
          if (apiResponse.code === 200) {
            const loginResponse = apiResponse.data;
            const token = loginResponse.accessToken;

            // 将 token 和 merchantId 保存到本地的 localStorage
            const base64Token = btoa(token);
            localStorage.setItem("token", base64Token);

            dispatch(setToken(base64Token));
          }
        });
    }
  }, [token, merchantId]);

  /**
   * 3. 获取到 token 后，请求商户的 配置信息。
   */
  useEffect(() => {
    if (token !== "" && merchantId !== "" && !isInitialized) {
      dispatch(loadMerchantSetting());
    }
  }, [token, merchantId, isInitialized, dispatch]);

  /**
   * 4. setting 数据初始化后，设置语言选项
   */
  useEffect(() => {
    if (isInitialized) {
      const majorLanguage = setting.basicSetting.majorLanguage.toLowerCase();
      if (majorLanguage != "en")
        dispatch(setAvailableLanguages(["en", majorLanguage]));
    }
  }, [isInitialized, dispatch]);

  /**
   * 3. 询问用户的语言偏好。用户选择后，放入本地 localStorage 缓存
   */
  useEffect(() => {
    const showModal = async () => {
      Dialog.show({
        content: "Choose Your Preferred Language?",
        closeOnAction: true,
        actions: [
          {
            key: availableLanguages[0],
            text: Constant.I18N_TEXT_MAP[
              availableLanguages[0] as keyof typeof Constant.I18N_TEXT_MAP
            ],
            onClick: () => handleUserChoice(availableLanguages[0]),
          },
          {
            key: availableLanguages[1],
            text: Constant.I18N_TEXT_MAP[
              availableLanguages[1] as keyof typeof Constant.I18N_TEXT_MAP
            ],
            onClick: () => handleUserChoice(availableLanguages[1]),
          },
        ],
      });
    };

    if (
      availableLanguages.length > 1 &&
      merchantId !== "" &&
      chosenLanguage === ""
    ) {
      showModal();
    }
  }, [availableLanguages]);

  /**
   * 5. 如果是打包带走的二维码，则请求生成一个新的 tableId，并放入本地缓存
   */
  useEffect(() => {
    if (
      token !== "" &&
      merchantId !== "" &&
      tableId === "0" &&
      packing === true
    ) {
      const tableIdKey = "pack_tableId_" + merchantIdFromQRCode;
      let newTableId = localStorage.getItem(tableIdKey);
      if (!newTableId) {
        const url = "/menu-factory/requestNewTableId";
        axiosInstance.get(url).then((response) => {
          const apiResponse: ApiResponse = response.data;
          if (apiResponse.code === 200) {
            newTableId = apiResponse.data.newTableId;
            localStorage.setItem(tableIdKey, newTableId as string);
            dispatch(setTableId(newTableId));
          }
        });
      } else {
        dispatch(setTableId(newTableId));
      }
    }
  }, [token, merchantId, tableId, packing, dispatch]);

  /**
   * 5. 如果不是打包带走的二维码，则请求当前扫码的餐桌编号
   */
  useEffect(() => {
    if (token !== "" && tableId !== "0" && packing === false) {
      const url = "/menu-factory/getTableNumberByTableId/" + tableIdFromQRCode;
      axiosInstance.get(url).then((response) => {
        const apiResponse: ApiResponse = response.data;
        if (apiResponse.code === 200) {
          const tableNumber = apiResponse.data.tableNumber;
          dispatch(setTableNumber(tableNumber));
        } else if (apiResponse.code === 400) {
          Toast.show({
            content: (
              <div className="break-normal tracking-wide">
                {t("app.invalidTable")}
              </div>
            ),
          });
        }
      });
    }
  }, [token, tableId, packing, dispatch]);

  /**
   * 6. 进入首页时，首先加载购物车数据
   */
  useEffect(() => {
    if (cartData.length === 0 && token !== "" && tableId !== "0") {
      dispatch(loadAllDishCartData(tableId as string));
    }
  }, [cartData, token, tableId, dispatch]);

  /**
   * 当购物车数据发生变化时，重新设置数量, 并计算价格.
   * 只有添加购物车时，延迟500毫秒，配合坠落动画。移除购物车时，不延迟
   */
  useEffect(() => {
    if (cartData.length > 0) {
      if (count < cartData.length && count > 0) {
        delay(300).then(() => {
          setCount(cartData.length);
        });
      } else {
        setCount(cartData.length);
      }
      // dispatch(calculateFinalPrice());
    }
  }, [cartData]);

  /**
   * 切换语言
   */
  useEffect(() => {
    if (chosenLanguage !== "") {
      i18n.changeLanguage(chosenLanguage);
    }
  }, [chosenLanguage]);

  /**
   * 购物车 Ref. 用于添加购物车时的坠落动画
   */
  useEffect(() => {
    if (cartRef !== null) {
      dispatch(setCartRefId("homeCartId"));
    }
  }, [cartRef]);

  /**
   * 处理用户的语言偏好选择
   * @param key
   */
  const handleUserChoice = (key: string) => {
    dispatch(setChosenLanguage(key));

    if (key === availableLanguages[0]) {
      Toast.show({
        content:
          t("app.langResult") +
          " " +
          Constant.I18N_TEXT_MAP[
            availableLanguages[0] as keyof typeof Constant.I18N_TEXT_MAP
          ],
        position: "bottom",
      });
    } else if (key === availableLanguages[1]) {
      Toast.show({
        content:
          t("app.langResult") +
          " " +
          Constant.I18N_TEXT_MAP[
            availableLanguages[1] as keyof typeof Constant.I18N_TEXT_MAP
          ],
        position: "bottom",
      });
    }
  };

  /**
   * 切换底部面板
   * @param value
   */
  const setActivePannal = (value: string) => {
    // 如果切换的目标面板是 购物车, 则打开一个新的购物车页面，不切换到购物车面板
    if (value === "cart") {
      dispatch(setPage("cart"));
      return;
    }
    dispatch(setHomeActivePannel(value));
  };

  return (
    <div className="app">
      <div className="body">
        {homeActivePannel === "home" ? (
          <Home tableNumber={tableNumber} />
        ) : homeActivePannel === "category" ? (
          <Category tableNumber={tableNumber} />
        ) : homeActivePannel === "cart" ? (
          <Cart />
        ) : (
          <Orders />
        )}
      </div>
      <div className="bottom">
        <TabBar
          activeKey={homeActivePannel}
          onChange={(value) => setActivePannal(value)}
        >
          <TabBar.Item
            key="home"
            icon={<FaceRecognitionOutline />}
            title={<div className="text-gray-600">{t("app.bottom.home")}</div>}
          />
          <TabBar.Item
            key="category"
            icon={<AppstoreOutline />}
            title={
              <div className="text-gray-600">{t("app.bottom.category")}</div>
            }
          />
          <TabBar.Item
            key="cart"
            icon={
              cartData.length > 0 ? (
                <Badge
                  content={count}
                  style={{ "--right": "0%", "--top": "20%" }}
                  className="p-0.5"
                >
                  <CartSmSVG />
                </Badge>
              ) : (
                <CartSmSVG />
              )
            }
            title={
              <div ref={cartRef} id="homeCartId" className="text-gray-600">
                {t("app.bottom.cart")}
              </div>
            }
          />
          <TabBar.Item
            key="orders"
            icon={<FileOutline />}
            title={
              <div className="text-gray-600">{t("app.bottom.orders")}</div>
            }
          />
        </TabBar>
      </div>
    </div>
  );
}

export default Main;
