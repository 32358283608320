import { createSlice } from "@reduxjs/toolkit";
import { ApiResponse, IDish } from "../../../definition/customTypes";
import { AppDispatch } from "../..";
import axiosInstance from "../../../definition/request";
import { loadDishThumbnail } from "../../../definition/utils";

interface IBestOffers {
  bestOffers: IDish[];
  isDataReady: boolean;
  isImageCoverLoaded: boolean;
}

const initialState: IBestOffers = {
  bestOffers: [],
  isDataReady: false,
  isImageCoverLoaded: false,
};

/**
 * 超值优惠 菜品
 */
const bestOffersSlice = createSlice({
  name: "bestOffers",
  initialState: initialState,
  reducers: {
    setBestOffers(state, action) {
      const offers = action.payload;
      state.bestOffers = offers.map((dish: IDish) => {
        return { ...dish, dishId: dish.id };
      });
    },
    setIsDataReady(state, action) {
      state.isDataReady = action.payload;
    },
    setIsImageCoverLoaded(state, action) {
      state.isImageCoverLoaded = action.payload;
    },
  },
});

const { setBestOffers, setIsDataReady, setIsImageCoverLoaded } =
  bestOffersSlice.actions;

const reducer = bestOffersSlice.reducer;

/**
 *
 * @returns 初始化 超值优惠菜品数据
 */
const initBestOffersData = () => {
  return async (dispatch: AppDispatch) => {
    const url = "/menu-factory/findDiscountingDishesByMerchantId";
    axiosInstance.get<ApiResponse>(url).then((response) => {
      const apiResponse: ApiResponse = response.data;
      // 成功
      if (apiResponse.code === 200) {
        const bestOffers: IDish[] = apiResponse.data;
        dispatch(setIsDataReady(true));
        if (bestOffers.length === 0) {
          return;
        }
        dispatch(setBestOffers(bestOffers));
      }
      // 失败
      else if (apiResponse.code === 500) {
        // message.error(apiResponse.msg);
      }
    });
  };
};

/**
 *
 * @param topDishes 更新封面图片
 */
const updateImageCove = (topDishes: IDish[]) => {
  return async (dispatch: AppDispatch) => {
    const tempBestOffers = await Promise.all(
      topDishes.map(async (dish) => {
        // const imageURL = await loadDishImage(dish.dishImages[0].uid);
        const imageURL = await loadDishThumbnail(dish.dishImages[0].uid);

        return { ...dish, imageCover: imageURL };
      })
    );
    dispatch(setBestOffers(tempBestOffers));
    dispatch(setIsImageCoverLoaded(true));
  };
};

export { initBestOffersData, updateImageCove };
export default reducer;
